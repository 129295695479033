import React, { useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const ConsumptionFacilityChart = ({ chartOptions }) => {
  const ref = useRef(null);

  if (!chartOptions) return null;

  return (
    <tr className='chart-row'>
      <td colSpan='20'>
        <HighchartsReact ref={ref} highcharts={Highcharts} options={chartOptions} />
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { consumptionByEntityByFacility, years } = state.consumption;

  const { entities, facility, metric } = state;

  const facilities = facility && facility.list;

  const { entityID } = ownProps;

  const entity = _.find(entities, { id: entityID });

  const byFacility = consumptionByEntityByFacility[entityID][years[years.length - 1]];

  const facilityIDs = byFacility && Object.keys(byFacility);

  const colors = state.branding?.colors?.palette;

  if (!facilities || !byFacility || facilityIDs.length <= 1) return {};

  const chartData = facilityIDs.map((facilityID) => {
    const sliceData = byFacility[facilityID];
    const facilityDetails = facilities && facilities.find(({ id }) => id === facilityID);

    return {
      ...sliceData,
      name: facilityDetails && facilityDetails.name,
      y: sliceData.co2e,
    };
  });

  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      borderRadius: 15,
      custom: {},
      events: {
        render() {
          const chart = this,
            series = chart.series[0];

          const total = series.data.reduce((sum, point) => {
            return point.visible ? sum + point.y : sum;
          }, 0);

          // Update the custom label in the main chart
          let customLabel = chart.options.chart.custom.label;

          const formattedTotal = total
            .toFixed(0) // Ensures it's an integer
            .toString() // Convert to string for replace()
            .replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ' ');

          if (!customLabel) {
            customLabel = chart.options.chart.custom.label = chart.renderer
              .label(
                `<span style="font-size: 1.8em;" >
                    <strong>${formattedTotal}</strong></span><br/>tCO2e`
              )
              .css({
                color: '#000',
                textAnchor: 'middle',
                fontFamily: 'Open Sans',
              })
              .add();
          } else {
            customLabel.attr({
              text: `<span style="font-size: 1.8em;" ><strong>${formattedTotal}</strong></span><br/>tCO2e`,
            });
          }

          const x = series.center[0] + chart.plotLeft;
          const y = series.center[1] + chart.plotTop - customLabel.attr('height') / 2;

          customLabel.attr({ x, y });
          customLabel.css({ fontSize: `${series.center[2] / 13}px` });
        },
      },
    },
    title: {
      text: `${entity.name} Facility Breakdown`,
    },
    tooltip: {
      formatter() {
        const perMetricData = [];

        metric.list.forEach((item) => {
          if (this.point[item.id]) {
            perMetricData.push(`${item.name}: ${this.point[item.id].toFixed(2)}`);
          }
        });

        return `<b>${this.key}</b><br/>CO2e: ${this.point.co2e.toFixed(2)}<br/>${perMetricData.join('<br/>')}`;
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        innerSize: '75%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.2f}% ({point.y:.2f} tCO2e)',
          style: {
            fontSize: '12px',
          },
        },
        showInLegend: true,
        colors,
      },
    },
    series: [
      {
        name: 'Brands',
        colorByPoint: true,
        data: _.orderBy(chartData, ['co2e', 'name'], ['desc', 'asc']),
      },
    ],
  };

  return {
    entity,
    chartOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConsumptionFacilityChart);
