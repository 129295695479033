import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Actions as USAGEACTIONS } from '../../../store/actions/usageActions';
import { UploadFileBtn } from '../../../styledComponents/GlobalStyle';

const UploadBtn = ({
  getUploadLink,
  processUpload,
  uploadType,
  usageUploadType,
  importUploadLink,
  importUploadKey,
  ...props
}) => {
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);
  let { action, toggleModal } = props || {};
  let btnDisabled = false;
  let uploadTypeValue = {};
  let btnTitle, btnIcon;
  //let isInputDisabled = false;

  if (action === 'deleteOrgs') {
    btnDisabled = !file || !importUploadLink || !importUploadKey || loader;
    btnTitle = 'Delete Organisations';
    btnIcon = 'fa-solid fa-trash-can';
    uploadTypeValue = { label: 'Delete Organisations', value: 'deleteOrgs' };
  } else if (action === 'importMixedTemplates') {
    btnDisabled = !file || !importUploadLink || !importUploadKey || loader;
    btnTitle = 'Import Mixed Template';
    btnIcon = 'fa-solid fa-upload';
    uploadTypeValue = { label: 'Import Mixed Templates', value: 'importMixedTemplates' };
  }

  const selectFile = (e) => {
    const newFile = e.target.files && e.target.files[0];
    setFile(newFile);
    uploadType(uploadTypeValue);
    getUploadLink(newFile, action);
  };

  return (
    <div>
      {file ? (
        <UploadFileBtn
          disabled={btnDisabled}
          onClick={async () => {
            setLoader(true);
            try {
              if (action === 'deleteOrgs') {
                await processUpload(file, action);
              } else if (action === 'importMixedTemplates') {
                await processUpload(file, action);
              }
            } catch (error) {
              console.log('Error:', error);
            } finally {
              setTimeout(() => {
                setLoader(false);
                toggleModal(false, null);
              }, 5000);
            }
          }}
          className='btn btn-success'>
          <i className={loader ? 'fa fa-spin fa-spinner' : btnIcon} style={{ marginRight: 5 }} />
          {btnTitle}
        </UploadFileBtn>
      ) : (
        <>
          <UploadFileBtn htmlFor='import_input' className='custom-file-upload btn'>
            <i className={loader ? `fa fa-spin fa-spinner` : 'fa fa-cloud-upload'} style={{ marginRight: 5 }} />
            Upload File
          </UploadFileBtn>
          <input
            id='import_input'
            type='file'
            disabled={file}
            accept='.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            onChange={selectFile}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    usageUploadType: state.usageUploadType,
    importUploadLink: state.importUploadLink,
    importUploadKey: state.importUploadKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  processUpload: (file, action) => dispatch(USAGEACTIONS.processUpload(file, action)),
  getUploadLink: (file, action) => dispatch(USAGEACTIONS.getUploadLink(file, action)),
  uploadType: (file) => dispatch(USAGEACTIONS.uploadUsageType(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadBtn);
