import _ from 'lodash';
import React from 'react';

import { connect } from 'react-redux';

const GreenhouseEmissionsAndIntensityRatioRow = ({
  report,
  totalEmissionsByScope,
  metrics,
  organisationDetails,
  organisationMetrics,
  scope,
  metricValues,
  industries,
  org,
  adminOrgs,
  currentOrganisation,
}) => {
  if (!report || !organisationDetails || !organisationMetrics || !totalEmissionsByScope) {
    return null;
  }

  if (org !== 'all') {
    metricValues = metricValues?.filter((item) => item.organisationId === org);
    organisationDetails = adminOrgs?.find((item) => item.id === org);
  } else {
    organisationDetails = adminOrgs?.find((item) => item.id === currentOrganisation);
  }

  const { year } = report;

  const hasComparative = totalEmissionsByScope && totalEmissionsByScope[year - 1];

  const getCo2eByScope = (market) => {
    let co2eTotal = 0;
    Object?.keys(totalEmissionsByScope[year])?.forEach((org) => {
      if (market) {
        co2eTotal += (totalEmissionsByScope[year][org][scope] && totalEmissionsByScope[year][org][scope].co2e_market) || 0;
      } else {
        co2eTotal += (totalEmissionsByScope[year][org][scope] && totalEmissionsByScope[year][org][scope].co2e) || 0;
      }
    });
    return co2eTotal;
  };

  const getAllCo2eScopes = (market) => {
    let co2eAllTotal = 0;
    Object?.keys(totalEmissionsByScope[year])?.forEach((org) => {
      Object?.keys(totalEmissionsByScope[year][org])?.forEach((scope) => {
        if (market) {
          co2eAllTotal +=
            (totalEmissionsByScope[year][org][scope] && totalEmissionsByScope[year][org][scope].co2e_market) || 0;
        } else {
          co2eAllTotal += (totalEmissionsByScope[year][org][scope] && totalEmissionsByScope[year][org][scope].co2e) || 0;
        }
      });
    });
    return co2eAllTotal;
  };

  const getCo2eComparativeByScope = (market) => {
    let co2eTotal = 0;
    Object?.keys(hasComparative)?.forEach((org) => {
      if (market) {
        co2eTotal += (hasComparative[org][scope] && hasComparative[org][scope].co2e_market) || 0;
      } else {
        co2eTotal += (hasComparative[org][scope] && hasComparative[org][scope].co2e) || 0;
      }
    });
    return co2eTotal;
  };

  const getAllCo2eComparativeScopes = (market) => {
    let co2eAllTotal = 0;
    Object?.keys(hasComparative)?.forEach((org) => {
      Object?.keys(hasComparative[org])?.forEach((scope) => {
        if (market) {
          co2eAllTotal += (hasComparative[org][scope] && hasComparative[org][scope].co2e_market) || 0;
        } else {
          co2eAllTotal += (hasComparative[org][scope] && hasComparative[org][scope].co2e) || 0;
        }
      });
    });
    return co2eAllTotal;
  };

  const co2e =
    org === 'all' && scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getCo2eByScope(false)) || 0
      : org === 'all' && !scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getAllCo2eScopes(false)) || 0
      : scope
      ? (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          totalEmissionsByScope[year][org][scope] &&
          totalEmissionsByScope[year][org][scope].co2e) ||
        0
      : (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          _.sumBy(Object?.values(totalEmissionsByScope[year][org]), 'co2e')) ||
        0;

  const co2eMarket =
    org === 'all' && scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getCo2eByScope(true)) || 0
      : org === 'all' && !scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getAllCo2eScopes(true)) || 0
      : scope
      ? (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          totalEmissionsByScope[year][org][scope] &&
          (totalEmissionsByScope[year][org][scope].co2e_market !== undefined
            ? totalEmissionsByScope[year][org][scope].co2e_market
            : totalEmissionsByScope[year][org][scope].co2e)) ||
        0
      : (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          _.sumBy(Object?.values(totalEmissionsByScope[year][org]), (item) =>
            item.co2e_market !== undefined ? item.co2e_market : item.co2e
          )) ||
        0;

  const co2eComparative =
    hasComparative && org === 'all' && scope
      ? (hasComparative && getCo2eComparativeByScope(false)) || 0
      : hasComparative && org === 'all' && !scope
      ? (hasComparative && getAllCo2eComparativeScopes(false)) || 0
      : hasComparative && scope
      ? (hasComparative && hasComparative[org] && hasComparative[org][scope] && hasComparative[org][scope].co2e) || 0
      : (hasComparative && hasComparative[org] && _.sumBy(Object?.values(hasComparative[org]), 'co2e')) || 0;

  const co2eMarketComparative =
    hasComparative && org === 'all' && scope
      ? (hasComparative && getCo2eComparativeByScope(true)) || 0
      : hasComparative && org === 'all' && !scope
      ? (hasComparative && getAllCo2eComparativeScopes(true)) || 0
      : scope
      ? (hasComparative &&
          hasComparative[org] &&
          hasComparative[org][scope] &&
          (hasComparative[org][scope].co2e_market !== undefined
            ? hasComparative[org][scope].co2e_market
            : hasComparative[org][scope].co2e)) ||
        0
      : (hasComparative &&
          hasComparative[org] &&
          _.sumBy(Object?.values(hasComparative[org]), (item) =>
            item.co2e_market !== undefined ? item.co2e_market : item.co2e || 0
          )) ||
        0;

  const metricValuesThisYear = metricValues?.find((metrics) => metrics.year == year);
  const metricValuesPrevYear = metricValues?.find((metrics) => metrics.year == year - 1);

  const revenueThisYear = metricValuesThisYear && metricValuesThisYear.originalTurnover / 1000000;
  const revenueLastYear = metricValuesPrevYear && metricValuesPrevYear.originalTurnover / 1000000;

  return (
    <tr key={crypto.randomUUID()}>
      <td align='left'>{scope ? `Scope ${scope}` : `All Scopes`}</td>
      <td className='toggle-co2e'>
        <span className='toggle-location'>
          {parseFloat(co2e || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}
        </span>
        <span className='toggle-market'>
          {parseFloat(co2eMarket || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}
        </span>
      </td>

      {hasComparative && (
        <td className='toggle-comparative toggle-co2e'>
          <span className='toggle-location'>
            {parseFloat(co2eComparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}
          </span>
          <span className='toggle-market'>
            {parseFloat(co2eMarketComparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}
          </span>
        </td>
      )}

      {
        <>
          <td style={{ textAlign: 'right' }} className='toggle-revenue'>
            <span className='toggle-location'>
              {revenueThisYear
                ? parseFloat(co2e / revenueThisYear).toLocaleString(navigator.language, { minimumFractionDigits: 3 })
                : 'N/A'}
            </span>
            <span className='toggle-market'>
              {revenueThisYear
                ? parseFloat(co2eMarket / revenueThisYear).toLocaleString(navigator.language, { minimumFractionDigits: 3 })
                : 'N/A'}
            </span>
          </td>

          {metricValues && [
            // Normalised by revenue
            hasComparative && (
              <td className='toggle-comparative toggle-revenue'>
                <span className='toggle-location'>
                  {revenueLastYear
                    ? parseFloat(co2eComparative / revenueLastYear).toLocaleString(navigator.language, {
                        minimumFractionDigits: 3,
                      })
                    : 'N/A'}
                </span>
                <span className='toggle-market'>
                  {revenueLastYear
                    ? parseFloat(co2eMarketComparative / revenueLastYear).toLocaleString(navigator.language, {
                        minimumFractionDigits: 3,
                      })
                    : 'N/A'}
                </span>
              </td>
            ),
          ]}
        </>
      }

      {org !== 'all' &&
        metricValues &&
        organisationDetails &&
        ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
          const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
          const metricID = orgIndustry?.[ir];
          const metric = metrics && metrics.find((metric) => metric.id === metricID);

          const metricValue = metricValuesThisYear && metricValuesThisYear[`metric${index + 1}`];
          const prevMetricValue = metricValuesPrevYear && metricValuesPrevYear[`metric${index + 1}`];

          return (
            metric &&
            metricID &&
            metricID !== '230393' && [
              <td style={{ textAlign: 'right' }} className={`toggle-m${index + 1}`} key={index}>
                <span className='toggle-location'>
                  {metricValue
                    ? parseFloat(co2e / metricValue).toLocaleString(navigator.language, { minimumFractionDigits: 3 })
                    : 'N/A'}
                </span>
                <span className='toggle-market'>
                  {metricValue
                    ? parseFloat(co2eMarket / metricValue).toLocaleString(navigator.language, { minimumFractionDigits: 3 })
                    : 'N/A'}
                </span>
              </td>,

              hasComparative && (
                <td className={`toggle-m${index + 1} toggle-comparative`} key={crypto.randomUUID()}>
                  <span className='toggle-location'>
                    {prevMetricValue
                      ? parseFloat(co2eComparative / prevMetricValue).toLocaleString(navigator.language, {
                          minimumFractionDigits: 3,
                        })
                      : 'N/A'}
                  </span>
                  <span className='toggle-market'>
                    {prevMetricValue
                      ? parseFloat(co2eMarketComparative / prevMetricValue).toLocaleString(navigator.language, {
                          minimumFractionDigits: 3,
                        })
                      : 'N/A'}
                  </span>
                </td>
              ),
            ]
          );
        })}
    </tr>
  );
};

const mapStateToProps = (state) => {
  const { totalEmissionsByScope, years, filter } = state.consumption || {};

  const { organisation, report } = state;
  const organisationMetrics = organisation?.metrics;

  const adminOrgs = state.admin?.organisations;
  const organisationList = state.organisation?.list;
  const { cyfAdmin, whiteLabelAdmin } = state.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin || false;

  return {
    report: report?.details,
    totalEmissionsByScope: totalEmissionsByScope,
    consumptionFilter: filter || [],
    years: years || [],
    metrics: state.metric?.list,
    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
    metricValues: organisation?.metrics ? organisation.metrics : null,
    industries: organisation?.industries,
    adminOrgs: adminUser ? adminOrgs : organisationList,
    currentOrganisation: state.currentOrganisation,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GreenhouseEmissionsAndIntensityRatioRow);
