import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import EmissionsBySourceEntity from './EmissionsBySourceEntity';
import EmissionsBySourceChart from './EmissionsBySourceChart';
import { Actions as REPORT_ACTIONS } from '../../store/actions/reportActions';
import { Actions as FACILITY_ACTIONS } from '../../store/actions/facilityActions';
import { Actions as CONSUMPTION_ACTIONS } from '../../store/actions/consumptionActions';

const GreenhouseEmissionsBySource = ({ report, entities, organisationDetails, organisationMetrics }) => {
  if (!report || !organisationDetails || !organisationMetrics) return null;

  return (
    <div>
      <div className='clearfix'></div>

      <br />
      <h3>Greenhouse Gas Emissions Disaggregated by Activity</h3>
      <br />

      <table id='GHGAndBySource-table' className='table table-hover general-table pie' style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <th width='20%'>Category</th>
            {/* <th className='comparative' width='20%' style={{ textAlign: 'right' }}> Comparative </th> */}
            <th width='20%' style={{ textAlign: 'right' }}>
              Total <br />
              (tCO2e)
            </th>
            {/* <th className='comparative' width='20%' style={{ textAlign: 'right' }}> Comparative </th> */}
            <th width='20%' style={{ textAlign: 'right' }}>
              Scope 1 (tCO2e)
            </th>
            {/* <th className='comparative' width='20%' style={{ textAlign: 'right' }}> Comparative </th> */}
            <th width='20%' style={{ textAlign: 'right' }}>
              Scope 2 (tCO2e)
            </th>
            {/* <th className='comparative' width='20%' style={{ textAlign: 'right' }}> Comparative </th> */}
            <th width='20%' style={{ textAlign: 'right' }}>
              Scope 3 <br />
              (tCO2e incl. WTT*)
            </th>
          </tr>
        </thead>
        <tbody>
          {entities?.map((entity) => (
            <EmissionsBySourceEntity key={entity.id} entityID={entity.id} />
          ))}
        </tbody>
      </table>

      <p>
        * WTT: Well-To-Tank emissions are upstream Scope 3 emissions associated with extraction, refining and transportation
        raw fuel sources to an organisation’s site or asset, prior to their combustion, as specified by GHG Protocol and
        Defra for separated reporting.
      </p>
      <br />
      <br />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    data,
    entities: consumptionEntities,
    subentities: consumptionSubentities,
    years,
    filter,
  } = state.consumption || {};

  const { subentities, entities, facility, organisation, report } = state;

  const neededEntities =
    entities &&
    consumptionEntities &&
    _.orderBy(
      entities.filter(({ id }) => consumptionEntities.includes(id)),
      ['name']
    );
  const neededSubentities =
    subentities &&
    consumptionSubentities &&
    _.orderBy(
      subentities.filter(({ id }) => consumptionSubentities.includes(id)),
      ['name']
    );

  return {
    report: report?.details,
    consumptionData: data,
    consumptionFilter: filter || [],
    subentities: neededSubentities,
    entities: neededEntities,
    years: years || [],
    facilities: facility?.list,
    facilityMetrics: facility?.metrics,
    metrics: state.metric?.list,

    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReport: (id) => dispatch(REPORT_ACTIONS.getReport(id)),
  getFacilityMetrics: () => dispatch(FACILITY_ACTIONS.getFacilityMetrics()),
  getConsumption: () => dispatch(CONSUMPTION_ACTIONS.getConsumption()),
  toggleConsumptionFilter: (filter) => dispatch(CONSUMPTION_ACTIONS.toggleConsumptionFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GreenhouseEmissionsBySource);
