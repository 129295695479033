import React from 'react';
import { connect } from 'react-redux';

import EmissionsDisaggregatedByCustomCategoryRow from './EmissionsDisaggregatedByCustomCategoryRow';

const EmissionsDisaggregatedByCustomCategory = ({ customLabels, emissionsByCustomCategory, orgs, currentOrganisation }) => {
  if (customLabels && customLabels.length === 0) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <div>
      <br />
      <br />
      <h3>Emissions disaggregated by custom category</h3>
      <table className='table table-hover general-table' style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <th width='19%'>Custom Category</th>
            <th width='19%' style={{ textAlign: 'right' }}>
              Organisation
            </th>
            <th width='15%' style={{ textAlign: 'right' }}>
              Total (tCO2e)
            </th>
            <th width='15%' style={{ textAlign: 'right' }}>
              Scope 1 (tCO2e)
            </th>
            <th width='15%' style={{ textAlign: 'right' }}>
              Scope 2 (tCO2e)
            </th>
            <th width='17%' style={{ textAlign: 'right' }}>
              Scope 3 (tCO2e incl. WTT*)
            </th>
          </tr>
        </thead>
        <tbody>
          {orgs?.map((org) => {
            return customLabels?.map((custCat, index) => {
              let categoryData =
                emissionsByCustomCategory && emissionsByCustomCategory[org] && emissionsByCustomCategory[org][custCat];

              return (
                <EmissionsDisaggregatedByCustomCategoryRow
                  key={`${org}-${index}`}
                  customCategory={custCat}
                  customCategoryData={categoryData}
                  organisation={org}
                />
              );
            });
          })}
        </tbody>
      </table>
      <br></br>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { emissionsByCustomCategory, customCategories } = state.consumption;
  const currentOrganisation = state.currentOrganisation;
  const { report } = state;

  const reportYear = report && report.details && report.details.year;
  const childOrgs = state.report?.includedOrgs || [];
  const orgs = [currentOrganisation, ...childOrgs];

  const customLabels = customCategories && customCategories.filter((custCat) => custCat !== null);

  return {
    emissionsByCustomCategory: emissionsByCustomCategory && reportYear && emissionsByCustomCategory[reportYear],
    customLabels,
    orgs,
    currentOrganisation,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsDisaggregatedByCustomCategory);
