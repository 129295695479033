import React, { useEffect } from 'react';
import { Actions } from '../../store/actions/organisationActions';
import { connect } from 'react-redux';
import OrganisationMetricRow from './OrganisationMetricRow';
import Tooltip from 'react-tooltip-lite';
import { AddButton } from '../../styledComponents/GlobalStyle';
import _ from 'lodash';
import { checkReportingYearEnd } from '../utils/GlobalFunctions';

const OrganisationMetrics = ({
  list,
  orgMetrics,
  addOrganisationMetric,
  orgDetails,
  getOrganisationMetrics,
  branding,
  addOrgMetricIssue,
}) => {
  const { primaryColor } = branding.colors;

  useEffect(() => {
    getOrganisationMetrics();
  }, [orgDetails.industry, getOrganisationMetrics]);

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <section className='panel' id='orgMetrics-panel'>
          <header className='panel-heading'>REVENUE AND PRODUCTIVITY DATA</header>
          <div className='panel-body'>
            <table className='table table-hover general-table'>
              <thead>
                <tr>
                  <th id='reportingPeriod'>Reporting Period</th>
                  <th id='orgRevenueMetric'>
                    <Tooltip
                      content='Your Revenue for the indicated period.'
                      background={primaryColor}
                      color='white'
                      styles={{ width: 'fit-content' }}
                      tipContentClassName='addUsageData-tooltip-content'>
                      Revenue
                      <i className='fa fa-info-circle' style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip>
                  </th>
                  {orgMetrics?.metric1 && orgMetrics?.metric1?.name !== 'revenue' && (
                    <th id='orgMetric1'>
                      <Tooltip
                        content={orgMetrics.metric1?.description}
                        background={primaryColor}
                        color='white'
                        styles={{ width: 'fit-content' }}
                        tipContentClassName='addUsageData-tooltip-content'>
                        {orgMetrics.metric1?.name}
                        <i className='fa fa-info-circle' style={{ cursor: 'pointer', marginLeft: 5 }} />
                      </Tooltip>
                    </th>
                  )}
                  {orgMetrics?.metric2 && orgMetrics?.metric2?.name !== 'revenue' && (
                    <th id='orgMetric2'>
                      <Tooltip
                        content={orgMetrics.metric2?.description}
                        background={primaryColor}
                        color='white'
                        styles={{ width: 'fit-content' }}
                        tipContentClassName='addUsageData-tooltip-content'>
                        {orgMetrics.metric2?.name}
                        <i className='fa fa-info-circle' style={{ cursor: 'pointer', marginLeft: 5 }} />
                      </Tooltip>
                    </th>
                  )}
                  {orgMetrics?.metric3 && orgMetrics?.metric3?.name !== 'revenue' && (
                    <th id='orgMetric3'>
                      <Tooltip
                        content={orgMetrics.metric3?.description}
                        background={primaryColor}
                        color='white'
                        styles={{ width: 'fit-content' }}
                        tipContentClassName='addUsageData-tooltip-content'>
                        {orgMetrics.metric3?.name}
                        <i className='fa fa-info-circle' style={{ cursor: 'pointer', marginLeft: 5 }} />
                      </Tooltip>
                    </th>
                  )}
                  <th></th>
                </tr>
              </thead>
              <tbody id='orgMetrics-Rows'>
                {list &&
                  Array.isArray(list) &&
                  list.map((metric, index) => (
                    <OrganisationMetricRow key={index} metric={metric} orgMetrics={orgMetrics} list={list} />
                  ))}
              </tbody>
            </table>
            <AddButton
              id='add-a-metric-button'
              className='btn btn-success'
              onClick={() => {
                !addOrgMetricIssue && addOrganisationMetric();
              }}
              bordercolor='true'>
              {addOrgMetricIssue ? 'Set reporting year end before adding a metric' : 'Add a Metric'}
            </AddButton>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const orgDetails = state.organisation?.details;
  const addOrgMetricIssue = checkReportingYearEnd(orgDetails);
  const metrics = state.metric?.list;
  const orgIndustry = _.find(state.organisation.industries, { id: orgDetails.industry });

  const orgMetrics = {};

  if (metrics && orgDetails) {
    if (orgIndustry?.intensityRatioMetric1ID) {
      const metric = metrics.find((item) => item.id === orgIndustry.intensityRatioMetric1ID);
      orgMetrics.metric1 = metric;
    }

    if (orgIndustry?.intensityRatioMetric2ID) {
      const metric = metrics.find((item) => item.id === orgIndustry.intensityRatioMetric2ID);
      orgMetrics.metric2 = metric;
    }

    if (orgIndustry?.intensityRatioMetric3ID) {
      const metric = metrics.find((item) => item.id === orgIndustry.intensityRatioMetric3ID);
      orgMetrics.metric3 = metric;
    }
  }

  let list =
    state.organisation?.metrics &&
    Array.isArray(state.organisation?.metrics) &&
    state.organisation?.metrics?.filter((item) => item?.organisationId == state.currentOrganisation);

  return {
    branding: state.branding,
    list: list,
    orgMetrics,
    orgDetails,
    addOrgMetricIssue,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOrganisationMetrics: () => dispatch(Actions.getOrganisationMetrics()),
  addOrganisationMetric: () => dispatch(Actions.addOrganisationMetric()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationMetrics);
