import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Types } from '../actions/organisationActions';

const defaultState = {};

const organisationReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_CURRENT: {
      let newState = { ...state };
      newState.organisation.details = action.organisation;
      newState.currentOrganisation = action.organisation?.id;

      return newState;
    }

    case Types.ORGANISATION_REPORT_YR_END_ISSUE: {
      let newState = _.cloneDeep(state);

      const { reportingYearEndIssue, orgMissingCompulsoryData } = action.response;

      newState.organisation['reportingYearEndIssue'] = reportingYearEndIssue;
      newState.organisation['orgMissingCompulsoryData'] = orgMissingCompulsoryData;

      return newState;
    }
    case Types.LIST: {
      let newState = _.cloneDeep(state);
      newState.organisation = state.organisation ? { ...state.organisation } : {};
      newState.organisation.list = action.payload;

      const orgList = newState.organisation.list;
      const urlId = window?.location?.href?.split('/')[4];

      // if sage/xero callback then get the orgId from the local storage.
      if (window.location.href.includes('/callback')) {
        newState.currentOrganisation = localStorage.getItem('organisation');
        newState.organisation.details = orgList.find((org) => org.id === newState.currentOrganisation);
      }
      //if not on organisation page and no id set
      else if (!window.location.href.includes('organisations/') && !newState.currentOrganisation) {
        const localOrgDetails = localStorage.getItem('orgDetails') && JSON.parse(localStorage.getItem('orgDetails'));
        const orgFromLocalStorage = orgList.length > 0 ? orgList.find((org) => org.id === localOrgDetails?.id) : undefined;
        if (orgFromLocalStorage) {
          // In case the localOrgDetails belongs to one of the orgs attached to the user get it from the list
          newState.organisation.details = orgFromLocalStorage;
          newState.currentOrganisation = orgFromLocalStorage.id;
        } else if (!orgFromLocalStorage && localOrgDetails?.id) {
          // Just for admin users, in case the localOrgDetails belongs to an org that is not attached to the user but user is admin of that org
          newState.organisation.details = localOrgDetails;
          newState.currentOrganisation = localOrgDetails.id;
        } else {
          newState.organisation.details = orgList[0];
          newState.currentOrganisation = orgList[0]?.id;
        }
      } else if (urlId) {
        newState.organisation.details = orgList?.find((org) => org?.id === urlId);
        newState.currentOrganisation = newState?.organisation?.details?.id;
      }
      newState.organisation.fetchedOrganisations = true;

      return newState;
    }
    case Types.INDUSTRIES: {
      let newState = _.cloneDeep(state);

      newState.organisation = state.organisation ? { ...state.organisation } : {};
      newState.organisation.industries = _.sortBy(action.payload, ['name']);

      return newState;
    }
    case Types.COUNTRIES: {
      let newState = _.cloneDeep(state);

      newState.organisation = state.organisation ? { ...state.organisation } : {};
      newState.organisation.countries = _.sortBy(action.payload, ['name']);

      return newState;
    }
    case Types.CURRENCIES: {
      let newState = _.cloneDeep(state);
      const data = action?.payload?.items;
      newState.organisation = state.organisation ? { ...state.organisation } : {};
      newState.organisation.currencies = data;

      return newState;
    }
    case Types.DETAILS: {
      let newState = _.cloneDeep(state);

      const { emailIdentityDNS, appDomainDNS, ...orgDetails } = action.payload || {};

      newState.organisation = state.organisation ? { ...state.organisation } : {};
      newState.currentOrganisation = action.payload?.id;

      newState.organisation.details = { ...orgDetails };

      newState.usageDataModal = {};
      return newState;
    }
    case Types.METRICS: {
      let newState = _.cloneDeep(state);

      newState.organisation = state.organisation ? { ...state.organisation } : {};
      newState.organisation.metrics = action.payload;

      return newState;
    }
    case Types.MODAL: {
      let newState = _.cloneDeep(state);

      newState.organisation.modal = {
        status: action.status,
        item: action.item,
        action: action.action,
      };

      return newState;
    }

    case Types.SAVE: {
      let newState = { ...state };

      const { cyfAdmin, whiteLabelAdmin } = newState.profile.details || {};
      const { id: orgId, organisation, organisation: updatedOrg } = action.payload?.data || {};

      localStorage.setItem('Organisation', orgId);

      newState.organisation.list = [...newState.organisation.list];

      const adminUser = whiteLabelAdmin || cyfAdmin;
      const orgList = adminUser ? newState.admin.organisations : newState.organisation.list;
      const orgIndex = orgList.findIndex((item) => item.id === orgId);

      if (orgIndex === -1) {
        newState.organisation.list.push(organisation);
        if (adminUser) newState.admin.organisations.push(organisation);
      } else {
        const index = newState.organisation.list.findIndex((item) => item.id === orgId);
        newState.organisation.list[index] = { ...newState.organisation.list[index], ...updatedOrg };
        if (adminUser) newState.admin.organisations[orgIndex] = { ...newState.admin.organisations[orgIndex], ...updatedOrg };
      }

      newState.organisation.details = organisation;
      newState.currentOrganisation = organisation.id;

      return newState;
    }

    case Types.SAVE_METRIC: {
      let newState = { ...state };

      const metricID = action.payload.data.id;
      const metric = action.metric;

      newState.organisation.metrics = [...newState.organisation.metrics];

      const metricIndex = newState.organisation.metrics.findIndex(
        (item) => item.id === metricID || (item.uuid && item.uuid === metric.uuid)
      );
      metric.id = metricID;
      newState.organisation.metrics[metricIndex] = metric;

      return newState;
    }
    case Types.ADD_METRIC: {
      let newState = { ...state };

      const uuid = uuidv4();

      newState.organisation.metrics = state.organisation.metrics ? [...state.organisation.metrics] : [];
      newState.organisation.metrics.push({
        organisationId: state.organisation.details.id,
        id: uuid,
        uuid,
      });

      return newState;
    }
    case Types.ADD_CHILD_ORGANISATION: {
      let newState = { ...state };

      const uuid = uuidv4();

      newState.organisation.hierarchy = state.organisation.hierarchy ? [...state.organisation.hierarchy] : [];
      newState.organisation.hierarchy.push({
        id: uuid,
        owner: state.organisation.details.id,
        percentage: '0',
        org: 'new',
        startDate: null,
        endDate: null,
      });

      return newState;
    }
    case Types.DELETE_METRIC: {
      const id = action.id;

      const newState = _.cloneDeep(state);

      newState.organisation.modal = {
        status: false,
        item: false,
      };

      const newOrganisationState = {
        ...newState.organisation,
      };

      //Deleting the Item From the State
      const updatingOrganisationState =
        newOrganisationState && newOrganisationState.metrics.filter((item) => item.id !== id);

      newState.organisation.metrics = updatingOrganisationState;

      return newState;
    }
    case Types.DELETE_HIERARCHY: {
      const id = action.id;

      const newState = _.cloneDeep(state);

      newState.organisation.modal = {
        status: false,
        item: false,
      };

      const newOrganisationState = {
        ...newState.organisation,
      };

      //Deleting the Item From the State
      const updatingOrganisationState =
        newOrganisationState && newOrganisationState.hierarchy.filter((item) => item.id !== id);

      newState.organisation.hierarchy = updatingOrganisationState;

      return newState;
    }
    case Types.ORGANISATION_USERS: {
      const newState = _.cloneDeep(state);

      let usersList = action.response;

      newState.organisation.users = usersList;

      return newState;
    }

    case Types.ORG_AGREEMENTS: {
      const newState = _.cloneDeep(state);

      const orgAgreements = action.payload;

      newState.profile.details.legalAgreements = { ...newState.profile.details.legalAgreements, ...orgAgreements };

      return newState;
    }

    case Types.LIST_WHITELABEL_ADMIN_ORGS: {
      const newState = _.cloneDeep(state);

      newState.admin.whiteLabelAdminOrgs = _.sortBy(action.response, ['name']);

      return newState;
    }

    case Types.LIST_WHITELABEL_ADMIN_SETTINGS: {
      const newState = _.cloneDeep(state);
      const wlSettings = action.response || {};

      newState.whiteLabelAdmin = { ...wlSettings };
      return newState;
    }

    case Types.SAVE_TRANSFERRED_ORG: {
      let newState = { ...state };

      newState.admin.organisations = [...newState.admin.organisations] || [];
      const orgIndex = newState.admin.organisations.findIndex((item) => item.id === action.org.id);

      if (orgIndex === -1) newState.admin.organisations.push(action.org);

      return newState;
    }

    case Types.ORGANISATION_HIERARCHY: {
      let newState = { ...state };

      newState.organisation.hierarchy = action.children;

      return newState;
    }

    case Types.ORGANISATION_PARENTS: {
      let newState = { ...state };
      if (action.parents?.message === 'Not Authorised') {
        newState.organisation.parents = [];
      } else {
        const parents = action.parents || [];
        newState.organisation.parents = parents?.map((parent) => parent.owner);
      }

      return newState;
    }

    case Types.ORGANISATION_CHILD_PARENTS: {
      let newState = { ...state };

      const parents = action.parents || [];
      newState.organisation.childParents = parents;

      return newState;
    }

    case Types.SET_PERIOD: {
      let newState = { ...state };

      newState.organisation.period = action.period;

      return newState;
    }

    default:
      return state;
  }
};

export default organisationReducers;
