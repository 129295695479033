import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const formatNumber = (value) => parseFloat(value || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 });

const EmissionsBySourceRow = ({
  entity,
  subentity,
  option,
  usageDetail,
  facility,
  report,
  org,
  adminOrgs,
  includedOrgs,
}) => {
  //Option is the consumption data for the year for that specific entity and subentity... i.e Consumption State -> Data[entityID][subentityID][entityID_subentityID_metric]._allFacilities
  if (!report) return null;
  const year = report.year;
  const hasMarketFactors = option?.[org]?.[year] && (option?.[org][year].co2e_market || option?.[org][year].market_mix);
  if (includedOrgs?.length > 0) {
    if (usageDetail?.length > 0) {
      usageDetail = ['Organisation: ' + adminOrgs?.find((o) => o.id === org)?.name, <br />].concat(usageDetail);
    } else {
      usageDetail.push(['Organisation: ' + adminOrgs?.find((o) => o.id === org)?.name]);
    }
  }
  return [
    <tr
      key={crypto.randomUUID()}
      className={`table-values ${facility ? `facility-values toggle-facility-${facility.id}` : ''} ${
        hasMarketFactors ? `toggle-location` : ''
      } ${`title-subrow-${entity?.name?.split(' ').join('').replace(':', '-')}`}`}
      style={{ background: 'rgba(0,0,0,0.0)' }}>
      <td style={{ textAlign: 'left' }}>
        {subentity.name} <span className='usage-detail'>{usageDetail}</span>
      </td>
      <td>{formatNumber(option?.[org]?.[year] ? _.sum(Object.values(option[org][year].scope)) : 0)}</td>
      <td>{formatNumber(option?.[org]?.[year] ? option[org][year].scope[1] : 0)}</td>
      <td>{formatNumber(option?.[org]?.[year] ? option[org][year].scope[2] : 0)}</td>
      <td>{formatNumber(option?.[org]?.[year] ? option[org][year].scope[3] : 0)}</td>
    </tr>,

    hasMarketFactors ? (
      <tr
        key={crypto.randomUUID()}
        className={`table-values ${facility ? `facility-values toggle-facility-${facility.id}` : ''} toggle-market`}
        style={{ background: 'rgba(0,0,0,0.0)' }}>
        <td style={{ textAlign: 'left' }}>
          {subentity.name} <span className='usage-detail'>{usageDetail}</span>
        </td>
        <td>
          {formatNumber(
            option?.[org]?.[year] && option[org][year].scopeMarket ? _.sum(Object.values(option[org][year].scopeMarket)) : 0
          )}
        </td>
        <td>
          {formatNumber(option?.[org]?.[year] && option[org][year].scopeMarket ? option[org][year].scopeMarket[1] : 0)}
        </td>
        <td>
          {formatNumber(option?.[org]?.[year] && option[org][year].scopeMarket ? option[org][year].scopeMarket[2] : 0)}
        </td>
        <td>
          {formatNumber(option?.[org]?.[year] && option[org][year].scopeMarket ? option[org][year].scopeMarket[3] : 0)}
        </td>
      </tr>
    ) : null,
  ];
};

const mapStateToProps = (state) => {
  const { years } = state.consumption;

  const { organisation, metric, report } = state;
  const adminOrgs = state.admin?.organisations;
  const organisationList = state.organisation?.list;
  const { cyfAdmin, whiteLabelAdmin } = state.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin || false;

  return {
    years: years || [],
    organisationDetails: organisation?.details,
    metricValues: organisation?.metrics ? organisation?.metrics : null,
    metrics: metric?.list,
    report: report?.details,
    adminOrgs: adminUser ? adminOrgs : organisationList,
    includedOrgs: state?.report?.includedOrgs,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsBySourceRow);
