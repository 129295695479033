import axios from 'axios';
import _ from 'lodash';
import createSignedRequest from './createSignedRequest';
import { showLoader, toggleAlertMessage, getStepFunctionStatus } from '../action.js';
import { checkReportingYearEndIssue } from './organisationActions';
import { updateConsumption } from './consumptionActions.js';
const { apiURL } = require('./apiURL');

const Types = {
  LIST: 'REPORT_LIST',
  DETAILS: 'REPORT_DETAILS',
  ADD_MODAL: 'REPORT_ADD_MODAL',
  ADD_REPORT: 'REPORT_ADD_REPORT',
  DOWNLOAD_STATUS: 'REPORT_DOWNLOAD_STATUS',
  INCLUDED_ORGS: 'REPORT_INCLUDED_ORGS',
  SHOW_CONSUMPTION_ALERT: 'REPORT_SHOW_CONSUMPTION_ALERT',
};

const setAddReportModal = (status, updateSubscription) => ({
  type: Types.ADD_MODAL,
  status,
  updateSubscription,
});

const updateAddReport = (response) => ({
  type: Types.ADD_REPORT,
  payload: response,
});

const addReport = (reportDates) => {
  return async (dispatch, getState) => {
    const state = getState();
    const updateSubscription = state.organisation?.details?.accountType == 'Direct User' && state.report.updateSubscription;
    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/report`, JSON.stringify({ reportDates, updateSubscription }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('addReport', response);

      if (response?.data?.error?.includes('card')) {
        return response.data;
      }

      if (response?.data?.message && response?.data?.success == false) {
        dispatch(toggleAlertMessage(true, response?.data?.message, 'error', 5000));
        return;
      }

      if (response?.data?.report?.success == false) {
        dispatch(toggleAlertMessage(true, response.data?.report?.message, 'error', 5000));
        return;
      }

      dispatch(updateAddReport(response.data));
      dispatch(checkReportingYearEndIssue(state.currentOrganisation));
    } catch (e) {
      console.log('addReport Error', e);
      return { error: e.message };
    }
  };
};

const updateReport = (response) => ({
  type: Types.DETAILS,
  payload: response,
});

const getReport = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    //In case the user opens the report in a new tab - get orgID from url, and send it in the request
    let orgID;

    if (window.location.pathname.includes('consumption')) {
      orgID = window.location.pathname.split('/')[2];
    } else if (window.location.pathname.includes('monthlyreport')) {
      orgID = window.location.pathname.split('/')[2];
    } else {
      orgID = window.location.pathname.split('/')[2] ? window.location.pathname.split('/')[2] : state.currentOrganisation;
    }

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/report/${id}`, null, { Organisation: String(orgID) })
      );

      console.log('getReport', response);
      dispatch(updateReport(response.data));
    } catch (e) {
      console.log('getReport Error', e);
    }
  };
};

export const updateDownloadStatus = (status, filetype) => ({
  type: Types.DOWNLOAD_STATUS,
  status,
  filetype,
});

const getReportPDF = (id, consumption, groupByMonth) => {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(updateDownloadStatus(true, 'pdf'));

    const isMarket = state.consumption && state.consumption.filter && !state.consumption.filter.includes('market');

    try {
      const response = await axios(
        await createSignedRequest(
          'GET',
          apiURL +
            `/my/report/${id}/pdf?${consumption ? `consumption=1` : ``}${
              consumption && (isMarket || groupByMonth) ? '&' : ''
            }${isMarket ? `market=1` : ``}${groupByMonth ? `groupByMonth=1` : ``}`,
          null,
          { Organisation: String(state.currentOrganisation) }
        )
      );

      console.log('getReportPDF', response);
      dispatch(getStepFunctionStatus(response.data.data.executionArn));
    } catch (e) {
      console.log('getReportPDF Error', e);
      dispatch(updateDownloadStatus(false, 'pdf'));
    }
  };
};

const getReportExcel = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    const reportYears = state.consumption?.years.sort((a, b) => b - a);

    const filters = state.consumption?.filter;
    const reportYear = state.report.details?.year;
    const years = reportYears.filter((year) => !filters.includes(`year-${year}`));
    const organisation = state?.organisation;

    years.splice(3, years.length - 3);

    const metricName =
      state.organisation?.details &&
      ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
        const orgIndustry = _.find(organisation?.industries, { id: organisation?.details?.industry });
        const metricID = orgIndustry?.[ir];
        const metric = state.metric.list?.find((metric) => metric.id !== 230393 && metric.id === metricID);

        //const metricName = metric && (metric.name == "Unit" ? state.organisation.details.nonStandardIntensityRatioLabel : `metric${index+1}`)
        const metricName = metric && `metric${index + 1}`;

        return metricName;
      });

    metricName.unshift('orgEmissions', 'tCO2Million');

    const updateMetricsName = [...filters];

    updateMetricsName.forEach((metric, index) => {
      if (metric === 'm1') {
        updateMetricsName[index] = 'metric1';
      } else if (metric === 'm2') {
        updateMetricsName[index] = 'metric2';
      } else if (metric === 'm3') {
        updateMetricsName[index] = 'metric3';
      } else if (metric === 'co2e') {
        updateMetricsName[index] = 'orgEmissions';
      } else if (metric === 'revenue') {
        updateMetricsName[index] = 'tCO2Million';
      }
    });

    const queryMetrics = metricName?.filter((metric) => !updateMetricsName?.includes(metric) && metric !== undefined);

    dispatch(updateDownloadStatus(true, 'excel'));

    let query = `/my/report/${id}/excel?years=${years.length > 0 ? years : reportYear}&metrics=${queryMetrics}`;

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + query.replaceAll(',', '-'), null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getReportExcel', response);
      dispatch(getStepFunctionStatus(response.data.data.executionArn));
    } catch (e) {
      dispatch(updateDownloadStatus(false, 'excel'));
      console.log('getReportExcel Error', e);
    }
  };
};

const updateReports = (response) => ({
  type: Types.LIST,
  payload: response,
});

const listReports = () => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(showLoader(true));
    const currentOrganisation = state.currentOrganisation;

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/reports`, null, { Organisation: String(currentOrganisation) })
      );

      console.log('listReports', response);
      //dispatch(updateConsumption({}));
      dispatch(updateReports(response.data));
      dispatch(showLoader(false));
    } catch (e) {
      console.log('listReports Error', e);
      dispatch(showLoader(false));
    }
  };
};

const clearReportCacheDate = (reportId) => {
  return async (dispatch, getState) => {
    const state = getState();

    const currentOrganisation = state.currentOrganisation;

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/report/clear-cache-date`, JSON.stringify({ reportId }), {
          Organisation: String(currentOrganisation),
        })
      );

      console.log('clearReportCacheDate', response);
      dispatch(toggleAlertMessage(true, 'Report Cache Date Successfully Clear'));
    } catch (e) {
      console.log('clearReportCacheDate Error', e);
    }
  };
};

const saveIncludedOrgs = (orgs) => ({
  type: Types.INCLUDED_ORGS,
  orgs,
});

const showConsumptionAlert = (show) => ({
  type: Types.SHOW_CONSUMPTION_ALERT,
  show,
});

const Actions = {
  listReports,
  getReport,
  getReportPDF,
  getReportExcel,
  setAddReportModal,
  addReport,
  clearReportCacheDate,
  updateDownloadStatus,
  saveIncludedOrgs,
  showConsumptionAlert,
};

export { Actions, Types };
