import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

const formatNumber = (value) => parseFloat(value || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 });

const EmissionsDisaggregatedByCustomCategoryRow = ({ customCategory, customCategoryData, organisation, organisations }) => {
  if (!customCategoryData) {
    return ``;
  }

  return (
    <tr>
      <td width='19%' style={{ textAlign: 'left' }}>
        {customCategory}
      </td>
      <td width='19%'>{organisations && organisations?.find((item) => item.id == organisation)?.name}</td>

      <td width='15%'>
        <span className='toggle-location'>
          {customCategoryData.co2e && customCategoryData.co2e ? customCategoryData.co2e.toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {formatNumber(
            customCategoryData && customCategoryData.marketScope ? _.sum(Object.values(customCategoryData.marketScope)) : 0
          )}
        </span>
      </td>

      <td width='15%'>
        <span className='toggle-location'>
          {customCategoryData.scope && customCategoryData.scope[1] ? customCategoryData.scope[1].toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {customCategoryData.marketScope && customCategoryData.marketScope[1]
            ? customCategoryData.marketScope[1].toFixed(3)
            : customCategoryData.scope[1]
            ? customCategoryData.scope[1].toFixed(3)
            : '0.00'}
        </span>
      </td>

      <td width='15%'>
        <span className='toggle-location'>
          {customCategoryData.scope && customCategoryData.scope[2] ? customCategoryData.scope[2].toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {customCategoryData.marketScope && customCategoryData.marketScope[2]
            ? customCategoryData.marketScope[2].toFixed(3)
            : '0.00'}
        </span>
      </td>

      <td width='17%'>
        <span className='toggle-location'>
          {customCategoryData.scope && customCategoryData.scope[3] ? customCategoryData.scope[3].toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {customCategoryData.marketScope && customCategoryData.marketScope[3]
            ? customCategoryData.marketScope[3].toFixed(3)
            : '0.00'}
        </span>
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => {
  const adminOrgs = state.admin?.organisations;
  const orgnaisationList = state.organisation?.list;
  const { cyfAdmin, whiteLabelAdmin } = state.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin || false;

  return {
    organisations: adminUser ? adminOrgs : orgnaisationList,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsDisaggregatedByCustomCategoryRow);
