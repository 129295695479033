import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../store/actions/facilityActions';
import { Actions as ORGANISATION_ACTIONS } from '../../store/actions/organisationActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import Breadcrumb from '../../components/Common/Breadcrumb';
import moment from 'moment';
import { FormControlLabel, Tooltip } from '@mui/material';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import { CalendarBtn } from '../../styledComponents/GlobalStyle';
import { parseISO, format, addDays } from 'date-fns';
import Checkbox from '@mui/material/Checkbox';
import TreeGraph from './TreeGraph';

const OrganisationHierarchyModal = ({ setOpen, data, branding }) => {
  const [date, setDate] = useState(null);
  const { primaryColor, secondaryColor } = branding || {};

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '90vh', fontSize: '12px', width: '70vw' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              className='close'
              onClick={() => {
                setOpen(false);
              }}>
              x
            </button>
            <h4 className='modal-title'>Organisation Hierarchy</h4>
          </div>
          <div className='modal-body' style={{ overflow: 'auto' }}>
            <label
              className='input-group date datepicker'
              style={{ position: 'absolute', marginRight: '1rem', width: '20%', right: 0 }}>
              <DatePicker
                showMonthDropdown
                showYearDropdown
                dateFormat='dd/MM/yyyy'
                name='startDate'
                placeholderText='Date'
                className='form-control startDate'
                selected={date}
                onChange={(date) => setDate(date)}
                popperPlacement='auto'
                isClearable={true}
              />
              <span className='input-group-btn' style={{ zIndex: 1 }}>
                <CalendarBtn className='btn btn-primary date-set'>
                  <i className='fa fa-calendar' />
                </CalendarBtn>
              </span>
              <Tooltip
                title={<span style={{ fontSize: 12 }}>Show company hierarchy for the selected date</span>}
                placement='right'
                componentsProps={{ tooltip: { sx: { bgcolor: secondaryColor } } }}>
                <i
                  className='fa fa-question-circle'
                  style={{ position: 'relative', top: '-10px', zIndex: 1, fontSize: 18, marginLeft: 5, color: primaryColor }}
                />
              </Tooltip>
            </label>
            {/* Key prop to force re-render */}
            <TreeGraph key={date ? date.getTime() : null} date={date} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

const OrganisationHierarchyRow = ({
  orgHierarchy,
  toggleModal,
  organisationList,
  organisationOptions,
  list,
  saveOrganisationHierarchy,
  currentOrganisation,
  removeNewHierarchy,
  getChildParents,
  childParents,
  primaryColor,
  secondaryColor,
  setPeriod,
  period,
}) => {
  const [editing, setEditing] = useState(false);
  const [editedState, setEditedState] = useState(null);
  const [invalidInput, setInvalidInput] = useState([]);
  const [wasStartDateCleared, setWasStartDateCleared] = useState(false);
  const [wasEndDateCleared, setWasEndDateCleared] = useState(false);
  const [isChecked, setIsChecked] = useState(orgHierarchy.endDate === '2099-12-31' ? true : false);
  const [ongoing, setOngoing] = useState(isChecked);
  const [percentageMessage, setPercentageMessage] = useState(null);
  const [periodMessage, setPeriodMessage] = useState(null);

  useEffect(() => {
    if (orgHierarchy.org === 'new') {
      setIsChecked(false);
      setOngoing(false);
      if (editedState?.['org'] === 'new' || !editedState) {
        setEditing(true);
      }
      if (!editedState && orgHierarchy.percentage === '0') {
        setInvalidInput(['org', 'percentage', 'startDate', 'endDate']);
      }
    } else {
      if (invalidInput.length < 1) {
        //setEditing(false);
      }
    }
  }, [list]);

  useEffect(() => {
    if (editedState?.org) {
      getChildParents(editedState?.org);
      if (orgHierarchy?.endDate === '2099-12-31' || editedState?.endDate === '2099-12-31') {
        setIsChecked(true);
      }
    }
  }, [editedState?.org]);

  useEffect(() => {
    if (editedState?.percentage && editedState?.startDate && editedState?.endDate) {
      let availablePercentage = 100; //100 - childParents?.reduce((acc, item) => acc + parseFloat(item.percentage), 0);
      let biggestPercentage = 0;
      //Checks if current dates are within other already saved periods and if they are we find the biggest percentage during all the periods and subtract it from 100
      childParents?.forEach((c) => {
        if (
          moment(editedState?.startDate).isBetween(start(c.startDate), end(c.endDate)) ||
          moment(editedState?.endDate).isBetween(start(c.startDate), end(c.endDate))
        ) {
          if (c.percentage > biggestPercentage) {
            biggestPercentage = c.percentage;
          }
        } else {
          //check if period contains other periods within it from other orgs
          if (
            moment(c.startDate).isBetween(start(editedState?.startDate), end(editedState?.endDate)) ||
            moment(c.endDate).isBetween(start(editedState?.startDate), end(editedState?.endDate))
          ) {
            if (c.percentage > biggestPercentage) {
              biggestPercentage = c.percentage;
            }
          }
        }
      });
      availablePercentage -= biggestPercentage;
      if (parseFloat(editedState?.percentage) > availablePercentage) {
        setInvalidInput([...invalidInput, 'percentage']);
        setPercentageMessage(
          `Another company has ownership of this company during this period and the remaning percentage is limited to: ${availablePercentage}%`
        );
      }
    }
  }, [editedState?.percentage, editedState?.startDate, editedState?.endDate]);

  useEffect(() => {
    //Turns off the red border around the 'same period' row after 5 seconds
    if (period) {
      setTimeout(() => {
        setPeriod(null);
      }, 5000);
    }
  }, [period]);

  const start = (date) => {
    //Subtracts 1 day from the date
    return format(addDays(new Date(date), -1), 'yyyy-MM-dd');
  };

  const end = (date) => {
    //Adds 1 day to the date
    return format(addDays(new Date(date), 1), 'yyyy-MM-dd');
  };

  const updateField = (e) => {
    const newEditedState = { ...editedState };

    newEditedState[e.target.name] = e.target.value.replace(/,(?=\d{3})/g, '');
    // Check for wrong values -> blocking users from inputting unwanted values
    if (
      parseFloat(newEditedState[e.target.name]) === 0 ||
      newEditedState[e.target.name] == '' ||
      newEditedState[e.target.name] == null ||
      parseFloat(newEditedState[e.target.name]) > 100 ||
      newEditedState[e.target.name].includes('-')
    ) {
      if (!invalidInput.includes(e.target.name)) {
        setInvalidInput([...invalidInput, e.target.name]);
      }
    } else {
      setInvalidInput(invalidInput.filter((item) => item !== e.target.name));
      setPercentageMessage(null);
    }

    setEditedState(newEditedState);
  };

  const borderStyle = (field) => {
    if (invalidInput.includes(field)) {
      return '1px solid red';
    } else {
      return '';
    }
  };

  const handleOngoing = (e) => {
    if (e.target.checked === true) {
      const newEditedState = { ...editedState };
      newEditedState['endDate'] = '2099-12-31';
      setIsChecked(true);
      setOngoing(true);
      setWasEndDateCleared(false);
      setInvalidInput(invalidInput.filter((item) => item !== 'endDate'));
      setEditedState(newEditedState);
    } else {
      const newEditedState = { ...editedState };
      newEditedState['endDate'] = null;
      setIsChecked(false);
      setOngoing(false);
      setWasEndDateCleared(true);
      setInvalidInput([...invalidInput, 'endDate']);
      setEditedState(newEditedState);
    }
  };

  const makeSaveObj = () => {
    let saveObj = {
      id: orgHierarchy.id,
      owner: orgHierarchy.owner,
      org: editedState?.['org'] || orgHierarchy.org,
      startDate: editedState?.['startDate'] || orgHierarchy.startDate,
      endDate: editedState?.['endDate'] || orgHierarchy.endDate,
      percentage: parseInt(editedState?.['percentage']) || parseInt(orgHierarchy.percentage),
      name: editedState?.['org'] ? organisationList?.find((o) => o.id === editedState?.['org'])?.name : orgHierarchy.name,
    };
    return saveObj;
  };

  const checkForPeriod = (startDate, endDate, org, id) => {
    const sameOrg = list.filter((child) => child.org === org && child.id !== id);
    let ret = false;
    if (sameOrg) {
      sameOrg.forEach((c) => {
        if (c.startDate >= startDate && c.endDate <= endDate) {
          //Another hierarchy is saved within this period
          setPeriod(c.id);
          setPeriodMessage(
            'There is already an ownership saved within this period, please change the dates or amend the other period.'
          );
          setInvalidInput([...invalidInput, 'startDate', 'endDate']);
          ret = true;
        } else if (c.startDate <= startDate && c.endDate >= endDate) {
          setPeriod(c.id);
          setPeriodMessage(
            'This ownership is within a period of another ownership, please change the dates or amend the other period.'
          );
          setInvalidInput([...invalidInput, 'startDate', 'endDate']);
          ret = true;
        } else if (moment(startDate).isAfter(endDate)) {
          setPeriodMessage('The start date cannot be after the end date');
        }
      });
      return ret;
    } else {
      return false;
    }
  };

  const canSave = (saveObj) => {
    if (checkForPeriod(saveObj.startDate, saveObj.endDate, saveObj.org, saveObj.id) === true) {
      setInvalidInput([...invalidInput, 'startDate', 'endDate']);
      return false;
    }
    if (invalidInput.length > 0) {
      return false;
    } else if (saveObj.org === 'new' || saveObj.org === null) {
      return false;
    } else if (saveObj.startDate === null) {
      return false;
    } else if (saveObj.endDate === null) {
      return false;
    } else if (saveObj.percentage === null || saveObj.percentage === '0') {
      return false;
      /*} else if (checkForPeriod(saveObj.startDate, saveObj.endDate, saveObj.org, saveObj.id) === true) {
       setInvalidInput([...invalidInput, 'startDate', 'endDate']);
      setPeriodMessage(
        'There is already an ownership saved within this period, please change the dates or amend the other period.'
      ); 
      return false; //There is another period in the middle of this period*/
    } else {
      return true;
    }
  };

  const checkDateStart = (startDate, id) => {
    const sameOrg = list.filter(
      (child) => (child.org === editedState?.org || child.org === orgHierarchy?.org) && child.id !== id
    );
    if (sameOrg) {
      if (sameOrg.some((child) => child.startDate <= startDate && child.endDate >= startDate)) {
        //new startDate entered is within the date span of another child
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const checkDateEnd = (endDate, id) => {
    const sameOrg = list.filter(
      (child) => (child.org === editedState?.org || child.org === orgHierarchy?.org) && child.id !== id
    );
    if (sameOrg) {
      return sameOrg.some((child) => moment(endDate).isBetween(start(child.startDate), end(child.endDate)));
    } else return true;
  };

  if (editing === true) {
    return (
      <tr>
        <td width='15%' style={{ textAlign: 'left' }}>
          <Select
            options={organisationOptions}
            value={organisationOptions?.find((o) => o.value === editedState?.org)}
            defaultValue={organisationOptions?.find((o) => o.value === orgHierarchy?.org)}
            key={orgHierarchy.id}
            name='org'
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: borderStyle('org'),
              }),
            }}
            onChange={(selected) => {
              const newEditedState = { ...editedState };
              newEditedState['org'] = selected.value;
              setInvalidInput(invalidInput.filter((item) => item !== 'org'));
              setEditedState(newEditedState);
            }}
          />
        </td>
        <td width='10%' style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', alignContent: 'center' }}>
            <Tooltip
              title={<span style={{ fontSize: 12 }}>{percentageMessage}</span>}
              placement='right'
              componentsProps={{
                tooltip: { sx: { bgcolor: secondaryColor, display: percentageMessage ? 'block' : 'none' } },
              }}>
              <i
                className='fa fa-exclamation-circle'
                style={{
                  fontSize: 18,
                  color: primaryColor,
                  marginRight: 2,
                  marginTop: 7,
                  display: percentageMessage ? 'block' : 'none',
                  float: 'right',
                }}
              />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <NumberFormat
                  thousandSeparator={true}
                  className='form-control'
                  inputMode='numeric'
                  onChange={updateField}
                  defaultValue={orgHierarchy.percentage}
                  name='percentage'
                  type='text'
                  style={{ border: borderStyle('percentage'), marginRight: '5px', width: '60%' }}
                />
                %
              </div>
            </Tooltip>
          </div>
        </td>
        <td width='15%' style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <Tooltip
              title={<span style={{ fontSize: 12 }}>{periodMessage}</span>}
              placement='right'
              componentsProps={{
                tooltip: { sx: { bgcolor: secondaryColor, display: periodMessage ? 'block' : 'none' } },
              }}>
              <i
                className='fa fa-exclamation-circle'
                style={{
                  fontSize: 18,
                  color: primaryColor,
                  marginRight: 2,
                  marginTop: 7,
                  display: periodMessage ? 'block' : 'none',
                  float: 'right',
                }}
              />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <label className='input-group date datepicker' style={{ marginBottom: 0, width: '80%' }}>
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    customInput={<input style={{ border: borderStyle('startDate'), borderRadius: '4px' }} />}
                    dateFormat='dd/MM/yyyy'
                    name='startDate'
                    placeholderText={'From'}
                    className='form-control startDate'
                    maxDate={new Date()}
                    selected={
                      wasStartDateCleared
                        ? null
                        : editedState?.['startDate']
                        ? parseISO(editedState['startDate'])
                        : orgHierarchy?.startDate && orgHierarchy?.startDate !== null
                        ? parseISO(orgHierarchy.startDate)
                        : null
                    }
                    onChange={(date) => {
                      const newEditedState = { ...editedState };
                      if (!date) {
                        newEditedState['startDate'] = null;
                        setWasStartDateCleared(true);
                        if (!invalidInput.includes('startDate')) {
                          setInvalidInput([...invalidInput, 'startDate']);
                        }
                        setEditedState(newEditedState);
                      } else {
                        newEditedState['startDate'] = format(date, 'yyyy-MM-dd');
                        if (checkDateStart(newEditedState['startDate'], orgHierarchy.id)) {
                          setInvalidInput(invalidInput.filter((item) => item !== 'startDate'));
                          setPeriodMessage(null);
                          setWasStartDateCleared(false);
                        } else {
                          if (!invalidInput.includes('startDate')) {
                            setInvalidInput([...invalidInput, 'startDate']);
                          }
                          setWasStartDateCleared(false);
                        }
                        setEditedState(newEditedState);
                      }
                    }}
                    popperPlacement='top-start'
                    isClearable={true}
                  />
                  <span className='input-group-btn'>
                    <CalendarBtn className='btn btn-primary date-set'>
                      <i className='fa fa-calendar' />{' '}
                    </CalendarBtn>
                  </span>
                </label>
              </div>
            </Tooltip>
          </div>
        </td>
        <td width='20%' style={{ textAlign: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
            }}>
            <div style={{ width: '50%' }}>
              <label className='input-group date datepicker' style={{ marginBottom: 0 }}>
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  customInput={<input style={{ border: borderStyle('endDate'), borderRadius: '4px' }} />}
                  dateFormat='dd/MM/yyyy'
                  name='endDate'
                  disabled={ongoing}
                  placeholderText={'To'}
                  className='form-control endDate'
                  selected={
                    ongoing === true
                      ? null
                      : wasEndDateCleared
                      ? null
                      : editedState?.['endDate']
                      ? editedState?.['endDate'] === '2099-12-31'
                        ? null
                        : parseISO(editedState['endDate'])
                      : orgHierarchy?.endDate && orgHierarchy?.endDate !== null
                      ? parseISO(orgHierarchy.endDate)
                      : null
                  }
                  onChange={(date) => {
                    const newEditedState = { ...editedState };
                    if (!date) {
                      newEditedState['endDate'] = null;
                      setWasEndDateCleared(true);
                      if (!invalidInput.includes('endDate')) {
                        setInvalidInput([...invalidInput, 'endDate']);
                      }
                      setEditedState(newEditedState);
                    } else {
                      newEditedState['endDate'] = format(date, 'yyyy-MM-dd');
                      if (checkDateEnd(newEditedState['endDate'], orgHierarchy.id)) {
                        if (!invalidInput.includes('endDate')) {
                          setInvalidInput([...invalidInput, 'endDate']);
                        }
                      } else if (
                        newEditedState['endDate'] > newEditedState['startDate'] ||
                        newEditedState['endDate'] > orgHierarchy?.startDate
                      ) {
                        setInvalidInput(invalidInput.filter((item) => item !== 'endDate'));
                        setPeriodMessage(null);
                      } else {
                        if (!invalidInput.includes('endDate')) {
                          setInvalidInput([...invalidInput, 'endDate']);
                        }
                      }
                      setWasEndDateCleared(false);
                      setEditedState(newEditedState);
                    }
                  }}
                  popperPlacement='top-start'
                  isClearable={isChecked === true ? false : true}
                />
                <span className='input-group-btn'>
                  <CalendarBtn className='btn btn-primary date-set'>
                    <i className='fa fa-calendar' />{' '}
                  </CalendarBtn>
                </span>
              </label>
            </div>
            <div style={{ width: '45%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => handleOngoing(e)}
                    style={{ padding: 0, marginLeft: '10px' }}
                  />
                }
                label='This is still ongoing'
                style={{ marginBottom: 0 }}
              />
            </div>
          </div>
        </td>
        <td width='5%'>
          <div style={{ marginTop: '8px' }}>
            <i
              style={{ cursor: 'pointer', paddingRight: 10, border: 0, background: 'inherit' }}
              onClick={() => {
                const saveObj = makeSaveObj();
                console.log('canSave: ', canSave(saveObj));
                if (canSave(saveObj) === true) {
                  saveOrganisationHierarchy(saveObj);
                  setEditing(false);
                }
              }}
              className={`fa fa-save fa-lg`}
              id='save-org-hierarchy '
            />
            <i
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (orgHierarchy.org === 'new') {
                  removeNewHierarchy(orgHierarchy.id);
                }
                setEditedState(null);
                setWasStartDateCleared(false);
                setWasEndDateCleared(false);
                setInvalidInput([]);
                setEditing(false);
              }}
              className='fa fa-times fa-lg'
              id='cancel-save-org-hierarchy'
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr style={{ border: period === orgHierarchy.id ? '2px solid red' : '', borderRadius: '4px' }}>
      <td width='15%' style={{ textAlign: 'left' }}>
        {orgHierarchy?.name || organisationList?.find((o) => o.id === orgHierarchy?.org)?.name}
      </td>
      <td width='10%' style={{ textAlign: 'center' }}>
        {orgHierarchy?.percentage + '%'}
      </td>
      <td width='15%' style={{ textAlign: 'center' }}>
        {moment(orgHierarchy?.startDate).format('DD/MM/YYYY')}
      </td>
      <td width='20%' style={{ textAlign: 'center' }}>
        {orgHierarchy?.endDate === '2099-12-31'
          ? 'Ownership is ongoing'
          : moment(orgHierarchy?.endDate).format('DD/MM/YYYY')}
      </td>
      {organisationList?.find((o) => o.id === orgHierarchy?.org) ? ( //Only allow editing if the user has access to the child company
        <td width='5%'>
          <i
            style={{ cursor: 'pointer' }}
            onClick={() => setEditing(true)}
            className='fa fa-pencil fa-lg'
            id='edit-org-hierarchy'
          />
          <i
            style={{ cursor: 'pointer', marginLeft: 10 }}
            onClick={() => toggleModal(true, orgHierarchy, 'deleteOrgHierarchy')}
            className='fa fa-trash-o fa-lg'
            id='delete-org-hierarchy'
          />
        </td>
      ) : (
        <td width='5%'></td>
      )}
    </tr>
  );
};

const OrganisationHierarchy = ({
  hierarchyList,
  currentOrganisation,
  branding,
  organisation,
  toggleModal,
  organisationList,
  organisationOptions,
  getOrgHierarchy,
  addChildOrganisation,
  saveOrganisationHierarchy,
  removeNewHierarchy,
  getOrgParents,
  parents,
  getChildParents,
  childParents,
  setPeriod,
  period,
}) => {
  const { body } = branding?.fonts?.[0]?.font || {};
  const { primaryColor, secondaryColor } = branding?.colors || {};
  const [list, setList] = useState(hierarchyList);
  const [sorter, setSorter] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setList(hierarchyList);
  }, [hierarchyList]);

  useEffect(() => {
    getOrgHierarchy(currentOrganisation);
    getOrgParents(currentOrganisation);
  }, [currentOrganisation]);

  const handleSort = (field, order) => {
    if (!list.some((child) => child.org === 'new')) {
      let newList = [];
      if (field === 'percentage') {
        if (order === 'desc') {
          newList = _.sortBy(list, [(o) => parseFloat(o?.percentage)]);
          setSorter({ field: 'percentage', order: 'asc' });
        } else {
          newList = _.sortBy(list, [(o) => parseFloat(o?.percentage)]).reverse();
          setSorter({ field: 'percentage', order: 'desc' });
        }
      } else if (field === 'org') {
        if (order === 'desc') {
          newList = _.sortBy(list, [(org) => organisationList?.find((o) => o.id === org?.org)?.name]);
          setSorter({ field: 'org', order: 'asc' });
        } else {
          newList = _.sortBy(list, [(org) => organisationList?.find((o) => o.id === org?.org)?.name]).reverse();
          setSorter({ field: 'org', order: 'desc' });
        }
      } else if (field === 'startDate') {
        if (order === 'desc') {
          newList = _.sortBy(list, [(o) => parseISO(o?.startDate)]);
          setSorter({ field: 'startDate', order: 'asc' });
        } else {
          newList = _.sortBy(list, [(o) => parseISO(o?.startDate)]).reverse();
          setSorter({ field: 'startDate', order: 'desc' });
        }
      } else if (field === 'endDate') {
        if (order === 'desc') {
          newList = _.sortBy(list, [(o) => parseISO(o?.endDate)]);
          setSorter({ field: 'endDate', order: 'asc' });
        } else {
          newList = _.sortBy(list, [(o) => parseISO(o?.endDate)]).reverse();
          setSorter({ field: 'endDate', order: 'desc' });
        }
      } else {
        newList = hierarchyList;
      }
      setList(newList);
    }
  };

  return (
    <>
      <Breadcrumb
        items={[
          { label: 'Organisations', link: '/organisations' },
          {
            label: (organisation && organisation?.name) || 'New',
            link: `/organisations/${organisation ? organisation?.id : 'new'}`,
          },
          { label: 'Organisational Hierarchy', link: `/organisations/${organisation?.id}/organisationHierarchy` },
        ]}
      />
      <div className='row' style={{ fontFamily: body }}>
        <div className='col-lg-12'>
          <section className='panel'>
            <header className='panel-heading'>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>Organisational Hierarchy</div>
                <div>
                  <AddButton
                    disabled={!hierarchyList?.length > 0 || !currentOrganisation || !organisationList}
                    onClick={() => {
                      setOpen(true);
                    }}>
                    <i className='fa fa-sitemap'></i>
                    {'  Organisation Hierarchy'}
                  </AddButton>
                </div>
              </div>
            </header>
            <div className='panel-body'>
              <table id='your-facilities-table' className='table table-hover general-table'>
                <thead>
                  <tr>
                    <th width='15%'>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        Subsidiary / Child Company
                        <Tooltip
                          title={
                            <span style={{ fontSize: 12 }}>
                              {'The company in which you had or have a controlling or minority ownership'}
                            </span>
                          }
                          placement='right'
                          componentsProps={{ tooltip: { sx: { bgcolor: secondaryColor } } }}>
                          <i
                            className='fa fa-question-circle'
                            style={{ fontSize: 18, color: primaryColor, marginLeft: 5 }}
                          />
                        </Tooltip>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <i
                            className='fa-solid fa-chevron-up'
                            style={{
                              marginLeft: 7,
                              marginBottom: 2,
                              cursor: 'pointer',
                              opacity: sorter?.field === 'org' && sorter?.order === 'desc' ? '100%' : '50%',
                            }}
                            onClick={() => handleSort('org', 'asc')}></i>
                          <i
                            className='fa-solid fa-chevron-down'
                            style={{
                              marginLeft: 7,
                              cursor: 'pointer',
                              opacity: sorter?.field === 'org' && sorter?.order === 'asc' ? '100%' : '50%',
                            }}
                            onClick={() => handleSort('org', 'desc')}></i>
                        </div>
                      </div>
                    </th>
                    <th width='10%' style={{ textAlign: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        Percentage Owned
                        <Tooltip
                          title={
                            <span style={{ fontSize: 12 }}>
                              {'The percentage ownership you have/had in this subsidiary or child company'}
                            </span>
                          }
                          placement='right'
                          componentsProps={{ tooltip: { sx: { bgcolor: secondaryColor } } }}>
                          <i
                            className='fa fa-question-circle'
                            style={{ fontSize: 18, color: primaryColor, marginLeft: 5 }}
                          />
                        </Tooltip>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <i
                            className='fa-solid fa-chevron-up'
                            style={{
                              marginLeft: 7,
                              marginBottom: 2,
                              cursor: 'pointer',
                              opacity: sorter?.field === 'percentage' && sorter?.order === 'desc' ? '100%' : '50%',
                            }}
                            onClick={() => handleSort('percentage', 'asc')}></i>
                          <i
                            className='fa-solid fa-chevron-down'
                            style={{
                              marginLeft: 7,
                              cursor: 'pointer',
                              opacity: sorter?.field === 'percentage' && sorter?.order === 'asc' ? '100%' : '50%',
                            }}
                            onClick={() => handleSort('percentage', 'desc')}></i>
                        </div>
                      </div>
                    </th>
                    <th width='15%' style={{ textAlign: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        Start Date{' '}
                        <Tooltip
                          title={
                            <span style={{ fontSize: 12 }}>
                              {'The date this percentage of ownership in the child company began'}
                            </span>
                          }
                          placement='right'
                          componentsProps={{ tooltip: { sx: { bgcolor: secondaryColor } } }}>
                          <i
                            className='fa fa-question-circle'
                            style={{ fontSize: 18, color: primaryColor, marginLeft: 5 }}
                          />
                        </Tooltip>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <i
                            className='fa-solid fa-chevron-up'
                            style={{
                              marginLeft: 7,
                              marginBottom: 2,
                              cursor: 'pointer',
                              opacity: sorter?.field === 'startDate' && sorter?.order === 'desc' ? '100%' : '50%',
                            }}
                            onClick={() => handleSort('startDate', 'asc')}></i>
                          <i
                            className='fa-solid fa-chevron-down'
                            style={{
                              marginLeft: 7,
                              cursor: 'pointer',
                              opacity: sorter?.field === 'startDate' && sorter?.order === 'asc' ? '100%' : '50%',
                            }}
                            onClick={() => handleSort('startDate', 'desc')}></i>
                        </div>
                      </div>
                    </th>
                    <th width='20%' style={{ textAlign: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        End Date{' '}
                        <Tooltip
                          title={<span style={{ fontSize: 12 }}>{'The date this percentage of ownership ended'}</span>}
                          placement='right'
                          componentsProps={{ tooltip: { sx: { bgcolor: secondaryColor } } }}>
                          <i
                            className='fa fa-question-circle'
                            style={{ fontSize: 18, color: primaryColor, marginLeft: 5 }}
                          />
                        </Tooltip>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <i
                            className='fa-solid fa-chevron-up'
                            style={{
                              marginLeft: 7,
                              marginBottom: 2,
                              cursor: 'pointer',
                              opacity: sorter?.field === 'endDate' && sorter?.order === 'desc' ? '100%' : '50%',
                            }}
                            onClick={() => handleSort('endDate', 'asc')}></i>
                          <i
                            className='fa-solid fa-chevron-down'
                            style={{
                              marginLeft: 7,
                              cursor: 'pointer',
                              opacity: sorter?.field === 'endDate' && sorter?.order === 'asc' ? '100%' : '50%',
                            }}
                            onClick={() => handleSort('endDate', 'desc')}></i>
                        </div>
                      </div>
                    </th>
                    <th width='5%'></th>
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    Array.isArray(list) &&
                    list.map((orgHierarchy, index) => (
                      <OrganisationHierarchyRow
                        key={index}
                        orgHierarchy={orgHierarchy}
                        toggleModal={toggleModal}
                        organisationList={organisationList}
                        organisationOptions={organisationOptions}
                        list={list}
                        saveOrganisationHierarchy={saveOrganisationHierarchy}
                        currentOrganisation={currentOrganisation}
                        removeNewHierarchy={removeNewHierarchy}
                        getChildParents={getChildParents}
                        childParents={childParents}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        setPeriod={setPeriod}
                        period={period}
                      />
                    ))}
                  <tr>
                    <td>
                      <AddButton
                        id='add-child-button'
                        onClick={() => {
                          //check to see if there is already a child with org === 'new'
                          if (!list.some((child) => child.org === 'new')) {
                            addChildOrganisation();
                          }
                        }}
                        className='btn btn-success'
                        disabled={list.some((child) => child.org === 'new')}>
                        Add new Child
                      </AddButton>
                    </td>

                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              {open && <OrganisationHierarchyModal setOpen={setOpen} data={hierarchyList} branding={branding?.colors} />}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganisation } = state;
  const orgDetails = state.organisation?.details;
  const parents = state.organisation?.parents;
  const childParents = state.organisation?.childParents?.filter((o) => o.owner !== currentOrganisation);

  return {
    hierarchyList:
      state.organisation?.hierarchy?.length > 0
        ? _.sortBy(
            state.organisation?.hierarchy?.filter((o) => o.owner === currentOrganisation),
            [(o) => parseFloat(o.percentage)]
          ).reverse()
        : [],
    currentOrganisation,
    branding: state.branding,
    organisation: state.organisation?.details,
    organisationList: state.organisation?.list,
    organisationOptions: _.sortBy(
      state?.organisation?.list
        ?.filter(
          (org) =>
            org.reportingYearEndDay === orgDetails?.reportingYearEndDay &&
            org.reportingYearEndMonth === orgDetails?.reportingYearEndMonth &&
            org.id !== orgDetails?.id
        )
        ?.filter((org) => !parents?.includes(org.id))
        ?.map((org) => ({ value: org.id, label: org.name })),
      [(o) => o.label]
    ),
    parents,
    childParents,
    period: state.organisation?.period,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showFacilityImportModal: (status) => dispatch(Actions.showFacilityImportModal(status)),
  getOrgHierarchy: (orgId) => dispatch(ORGANISATION_ACTIONS.getOrgHierarchy(orgId)),
  toggleModal: (status, item, action) => dispatch(ORGANISATION_ACTIONS.toggleModal(status, item, action)),
  addChildOrganisation: () => dispatch(ORGANISATION_ACTIONS.addChildOrganisation()),
  saveOrganisationHierarchy: (orgHierarchy) => dispatch(ORGANISATION_ACTIONS.saveOrganisationHierarchy(orgHierarchy)),
  removeNewHierarchy: (id) => dispatch(ORGANISATION_ACTIONS.removeNewHierarchy(id)),
  getOrgParents: (orgId) => dispatch(ORGANISATION_ACTIONS.getOrgParents(orgId)),
  getChildParents: (orgId) => dispatch(ORGANISATION_ACTIONS.getChildParents(orgId)),
  setPeriod: (id) => dispatch(ORGANISATION_ACTIONS.setPeriod(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationHierarchy);
