import axios from 'axios';
import _ from 'lodash';
import { Actions as CONSUMPTION_ACTIONS } from './actions/consumptionActions';
import createSignedRequest from './actions/createSignedRequest';
import { Actions as REPORT_ACTIONS } from './actions/reportActions';
import { Actions as USAGE_ACTIONS } from './actions/usageActions';
const { apiURL } = require('./actions/apiURL');

const Types = {
  TRACKING_CATEGORIES: 'TRACKING_CATEGORIES',
  ACCOUNT_CODES: 'ACCOUNT_CODES',
  SAGE_ACCOUNT_CODES: 'SAGE_ACCOUNT_CODES',
  SET_ACCOUNT_CODE: 'SET_ACCOUNT_CODE',
  SET_SAGE_ACCOUNT_CODE: 'SET_SAGE_ACCOUNT_CODE',
  SET_TRACKING_OPTION: 'SET_TRACKING_OPTION',
  ADD_TRACKING_OPTION: 'ADD_TRACKING_OPTION',
  CONNECTED_TO_XERO: 'CONNECTED_TO_XERO',
  XERO_CALLBACK: 'XERO_CALLBACK',
  SET_TRACKING_CATEGORY: 'SET_TRACKING_CATEGORY',

  CONNECTED_TO_SAGE: 'CONNECTED_TO_SAGE',
  SAGE_CALLBACK: 'SAGE_CALLBACK',
  SET_BULK_XERO_OPTIONS: 'SET_BULK_XERO_OPTIONS',
  ALL_TRACKING_OPTIONS: 'ALL_TRACKING_OPTIONS',
  SAVE_XERO_SETTINGS: 'SAVE_XERO_SETTINGS',
  SET_TENANT: 'SET_TENANT',
  SET_SAGE_TENANT: 'SET_SAGE_TENANT',
  SET_METHOD: 'SET_METHOD',
  GET_SETTINGS: 'GET_SETTINGS',
  GET_SAGE_SETTINGS: 'GET_SAGE_SETTINGS',
  SESSION: 'SESSION',
  GET_CLAIMS: 'GET_CLAIMS',
  GET_SPEND_ENTITIES: 'GET_SPEND_ENTITIES',
  GET_SAGE_SPEND_ENTITIES: 'GET_SPEND_ENTITIES',
  SELECT_YEAR: 'SELECT_YEAR',
  PROCESS_IMPORT_STATUS: 'PROCESS_IMPORT_STATUS',
  DISCONNECT_ORG: 'DISCONNECT_ORG',

  GET_ENTITIES: 'GET_ENTITIES',
  GET_SUBENTITIES: 'GET_SUBENTITIES',
  GET_FEATURES: 'GET_FEATURES',

  GET_LICENSES: 'GET_LICENSES',
  SHOW_FOOTER_AGREEMENTS: 'SHOW_FOOTER_AGREEMENTS',
  SHOW_ALERT_MESSAGE: 'SHOW_ALERT_MESSAGE',
  SHOW_LOADER: 'SHOW_LOADER',
  SHOW_MODAL: 'SHOW_MODAL',
  SHOW_ANALYSIS_MODAL: 'SHOW_ANALYSIS_MODAL',
  UPDATE_PROGRESS: 'UPDATE_PROGRESS',
};

export const showLoader = (response) => ({
  type: Types.SHOW_LOADER,
  response,
});

export const toggleAlertMessage = (status, message, severity, hideDuration) => ({
  type: Types.SHOW_ALERT_MESSAGE,
  status,
  message,
  severity,
  hideDuration,
});

export const updateProgressBar = (progress) => ({
  type: Types.UPDATE_PROGRESS,
  progress,
});

const showModal = (status) => ({
  type: Types.SHOW_MODAL,
  status,
});

const updateTrackingCategories = (response) => ({
  type: Types.TRACKING_CATEGORIES,
  payload: response,
});

const setTrackingCategory = (payload) => ({
  type: Types.SET_TRACKING_CATEGORY,
  payload,
});

const setBulkXeroOptions = (payload) => ({
  type: Types.SET_BULK_XERO_OPTIONS,
  payload,
});

const selectTrackingCategory = (trackingCategory) => {
  return async (dispatch, getState) => {
    const state = getState();

    const response = await axios(
      await createSignedRequest('GET', apiURL + `/xero/tracking-category/${trackingCategory.value}`, null, {
        Organisation: String(state.currentOrganisation),
      })
    );

    const { data } = response.data;

    dispatch(setTrackingCategory(data));
  };
};

const bulkAddXeroOptions = (selectedTrackingCategory) => {
  return async (dispatch, getState) => {
    dispatch(showLoader(true));
    const state = getState();

    console.log('selectedTrackingCategory', selectedTrackingCategory);

    const trackingCategory = _.cloneDeep(selectedTrackingCategory);

    const response = await axios(
      await createSignedRequest(
        'POST',
        apiURL +
          // `http://localhost:3001`
          `/xero/bulk/options`,
        JSON.stringify({ trackingCategory }),
        {
          Organisation: String(state.currentOrganisation),
        }
      )
    );

    const { payload } = response.data;
    console.log('payload', payload);

    dispatch(setBulkXeroOptions(payload));

    if (payload?.success) {
      dispatch(toggleAlertMessage(true, `Your new Xero Configuration has been saved`, 'success', 3000));
    }

    dispatch(showLoader(false));
  };
};

const selectYear = (year) => ({
  type: Types.SELECT_YEAR,
  year,
});

const disconnectOrg = (response) => ({
  type: Types.DISCONNECT_ORG,
  payload: response,
});

const setAccountCode = (account, value, removedValue) => ({
  type: Types.SET_ACCOUNT_CODE,
  account,
  value,
  removedValue,
});

const setSageAccountCode = (account, value, removedValue) => ({
  type: Types.SET_SAGE_ACCOUNT_CODE,
  account,
  value,
  removedValue,
});

const addTrackingOption = (category, item) => {
  return async (dispatch, getState) => {
    const optionName = `${item.name} CYF`;
    const response = await axios(
      await createSignedRequest(
        'POST',
        apiURL + `/xero/tracking-categories/${category}`,
        JSON.stringify({
          name: optionName,
        }),
        { Organisation: String(getState().currentOrganisation) }
      )
    );

    const { trackingCategory } = response.data;
    const trackingOption = _.find(trackingCategory.Options, { Name: optionName });

    dispatch(
      setTrackingOption(item.id, {
        label: optionName,
        value: trackingOption.TrackingOptionID,
      })
    );
  };
};

const setTrackingOption = (option, value, action) => ({
  type: Types.SET_TRACKING_OPTION,
  option,
  value,
  action,
});

const setAllTrackingOptions = (value) => ({
  type: Types.ALL_TRACKING_OPTIONS,
  value,
});

const setSavedXeroSettings = (payload) => ({
  type: Types.SAVE_XERO_SETTINGS,
  payload,
});

const setTenant = (data) => ({
  type: Types.SET_TENANT,
  data,
});

const setSageTenant = (data) => ({
  type: Types.SET_SAGE_TENANT,
  data,
});

const setMethod = (method) => ({
  type: Types.SET_METHOD,
  method,
});

const loadOrganisationSettings = (response) => ({
  type: Types.GET_SETTINGS,
  payload: response,
});

const loadSageOrganisationSettings = (response) => ({
  type: Types.GET_SAGE_SETTINGS,
  payload: response,
});

const updateXeroConnectionStatus = (response) => ({
  type: Types.CONNECTED_TO_XERO,
  payload: response,
});

const updateSageConnectionStatus = (response) => ({
  type: Types.CONNECTED_TO_SAGE,
  payload: response,
});

const updateAccountCodes = (response) => ({
  type: Types.ACCOUNT_CODES,
  payload: response,
});

const updateSageAccountCodes = (response) => ({
  type: Types.SAGE_ACCOUNT_CODES,
  payload: response,
});

const updateYears = (response) => ({
  type: Types.GET_CLAIMS,
  payload: response,
});

export const processingStepFunctionStatus = (response) => ({
  type: Types.PROCESS_IMPORT_STATUS,
  payload: response,
});

const setSession = (session) => {
  return {
    type: Types.SESSION,
    session: session,
  };
};

const updateSpendEntities = (response) => ({
  type: Types.GET_SPEND_ENTITIES,
  payload: response,
});

const updateSageSpendEntities = (response) => ({
  type: Types.GET_SAGE_SPEND_ENTITIES,
  payload: response,
});

const getSpendEntities = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/xero/entities`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getSpendEntities', response);
      dispatch(updateSpendEntities(response.data.entities));
    } catch (e) {
      console.log('isConnectedToXero Error:', e);
    }
  };
};

const getSageSpendEntities = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/sage/entities`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );
      dispatch(updateSageSpendEntities(response.data.entities));
    } catch (e) {
      console.log('isConnectedToXero Error:', e);
    }
  };
};

const isConnectedToXero = () => {
  return async (dispatch, getState) => {
    dispatch(showLoader(true));
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/xero/is-connected`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );
      const { data } = response || {};
      if (data?.alert) {
        dispatch(toggleAlertMessage(true, data?.message, 'error', 30000));
      }

      dispatch(updateXeroConnectionStatus(data));
      dispatch(showLoader(false));
    } catch (e) {
      dispatch(showLoader(false));
      console.log('isConnectedToXero Error:', e);
    }
  };
};

const isConnectedToSage = () => {
  return async (dispatch, getState) => {
    dispatch(showLoader(true));
    const state = getState();
    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/sage/is-connected`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      dispatch(updateSageConnectionStatus(response.data));
    } catch (e) {
      console.log('isConnectedToSage Error:', e);
    }
    dispatch(showLoader(false));
  };
};

const disconnectOrganisation = (type, tenantId) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios(
        await createSignedRequest('DELETE', apiURL + `/${type}/org-disconnect/${tenantId}`, '{}', {
          Organisation: String(state.currentOrganisation),
        })
      );
      const { data } = response;

      if (data.success) {
        dispatch(disconnectOrg(data));
      } else {
        dispatch(toggleAlertMessage(true, data?.error, 'error', 30000));
      }
    } catch (e) {
      console.log('disconnectOrg Error:', e);
    }
  };
};

const xeroCallback = () => {
  return async (dispatch, getState) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('state')?.split('?')?.[0];
    const userId = code?.split('=')[0];
    const orgId = code?.split('=')[1];

    fetch(apiURL + `/registration/xero/${orgId}/${userId}/callback` + window.location.search, {
      method: 'GET',
      body: null,
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('xeroCallback', response);
        const redirectUrl = response?.redirectUrl;
        window.location.replace(`https://${redirectUrl}/organisations/${orgId}`);
      })
      .catch((e) => console.log('isConnectedToXero Error:', e));
  };
};

const sageCallBack = () => {
  return async (dispatch, getState) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('state')?.split('?')?.[0];
    const userId = code?.split('=')[0];
    const orgId = code?.split('=')[1];

    fetch(apiURL + `/registration/sage/${orgId}/${userId}/callback` + window.location.search, {
      method: 'GET',
      body: null,
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('sageCallBack', response);
        const redirectUrl = response?.redirectUrl;
        window.location.replace(`https://${redirectUrl}/organisations/${orgId}`);
      })
      .catch((e) => console.log('sageCallBack Error:', e));
  };
};

const getAccountCodes = () => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/xero/accounts' + window.location.search, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('isConnectedToXero', response);
      dispatch(updateAccountCodes(response.data));
      //history.push("/organisations/" + state.currentOrganisation)
    } catch (e) {
      console.log('isConnectedToXero Error:', e);
    }
  };
};

const getSageAccountCodes = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/sage/accounts' + window.location.search, null, {
          Organisation: String(state.currentOrganisation),
        })
      );
      dispatch(updateSageAccountCodes(response.data));
    } catch (e) {
      console.log('isConnectedToSage Error:', e);
    }
  };
};

const processImport = (year) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/xero/import', JSON.stringify({ year }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('Process Xero Import', response);
      dispatch(getStepFunctionStatus(response.data.data.executionArn));
    } catch (e) {
      console.log('processImport Error:', e);
    }
  };
};

const processSageImport = (year) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/sage/import', JSON.stringify({ year }), {
          Organisation: String(state.currentOrganisation),
        })
      );
      console.log('Process Sage Import', response);
      dispatch(getStepFunctionStatus(response.data.data.executionArn));
    } catch (e) {
      console.log('processImport Error:', e);
    }
  };
};

const getYears = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/xero/years', null, { Organisation: String(state.currentOrganisation) })
      );

      console.log('getYears', response);
      dispatch(updateYears(response.data));
    } catch (e) {
      console.log('getYears Error:', e);
    }
  };
};

const getTrackingCategories = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/xero/tracking-categories', null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getTrackingCategories', response);
      dispatch(updateTrackingCategories(response.data));
    } catch (e) {
      console.log('getTrackingCategories Error:', e);
    }
  };
};

const saveOrganisationSettings = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/xero/settings', JSON.stringify(data), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('saveOrganisationSettings', response);
      const { data: responseData } = response || {};
      dispatch(setSavedXeroSettings(responseData));
      if (responseData.settings) {
        dispatch(toggleAlertMessage(true, 'Your new Xero Configuration has been saved', 'success', 3000));
      }
    } catch (e) {
      console.log('saveOrganisationSettings Error:', e);
    }
  };
};

const saveSageOrganisationSettings = (data) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/sage/settings', JSON.stringify(data), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('saveSageOrganisationSettings', response);
      dispatch(toggleAlertMessage(true, 'Your new Sage Configuration has been saved', 'success', 3000));
    } catch (e) {
      console.log('saveSageOrganisationSettings Error:', e);
    }
  };
};

const saveTenant = (tenant) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/xero/tenant', JSON.stringify({ tenant }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('saveTenant', response);
      dispatch(setTenant(response.data));
    } catch (e) {
      console.log('saveTenant Error:', e);
    }
  };
};

const saveSageTenant = (tenant) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + '/sage/tenant', JSON.stringify({ tenant }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('saveSageTenant', response);
      dispatch(setSageTenant(response?.data));
    } catch (e) {
      console.log('saveTenant Error:', e);
    }
  };
};

const getOrganisationSettings = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/xero/settings', null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getOrganisationSettings', response);
      dispatch(loadOrganisationSettings(response.data));
    } catch (e) {
      console.log('getOrganisationSettings Error:', e);
    }
  };
};

const getSageOrganisationSettings = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/sage/settings', null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getOrganisationSettings', response);
      dispatch(loadSageOrganisationSettings(response.data));
    } catch (e) {
      console.log('getOrganisationSettings Error:', e);
    }
  };
};

const updateEntities = (response) => ({
  type: Types.GET_ENTITIES,
  payload: response,
});

const getEntities = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios(await createSignedRequest('GET', apiURL + '/impact/usage-types', null));

      console.log('getEntities', response);
      dispatch(updateEntities(response.data));
    } catch (e) {
      console.log('getEntities Error:', e);
    }
  };
};

const updateSubentities = (response) => ({
  type: Types.GET_SUBENTITIES,
  payload: response,
});

const getSubentities = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios(await createSignedRequest('GET', apiURL + '/impact/usage-subtypes', null));

      console.log('getSubentities', response);
      dispatch(updateSubentities(response.data));
    } catch (e) {
      console.log('getSubentities Error:', e);
    }
  };
};

const updateLicenses = (response) => ({
  type: Types.GET_LICENSES,
  payload: response,
});

const getLicenses = () => {
  return async (dispatch) => {
    fetch(apiURL + '/licenses', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(updateLicenses(data));
      })
      .catch((error) => console.log('Error getting licenses', error));
  };
};

const showFooterLegalAgreements = (status, agreement) => ({
  type: Types.SHOW_FOOTER_AGREEMENTS,
  status: status,
  agreement: agreement,
});

export const showAnalysisModal = (status, action) => ({
  type: Types.SHOW_ANALYSIS_MODAL,
  status,
  action,
});

export function getStepFunctionStatus(importArn) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/my/stepFunctionStatus/' + importArn, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('Step Function Status', response.data);

      const execArn = response.data?.data?.executionArn;
      const status = response.data?.data?.status;

      dispatch(processingStepFunctionStatus(response.data));

      // Step Function Status is Running
      if (status === 'RUNNING') {
        if (execArn.includes('RecalculateUsageHelper')) {
          // For Recalculate Usage Show Message Saying Usage is Being Recalculated
          const message = 'The usage is being recalculated!';
          dispatch(toggleAlertMessage(true, message, 'success', 600000));
        } else if (execArn?.includes('ImportBills')) {
          const sage = execArn?.includes('Sage');
          const message = `We are currently processing your ${sage ? 'Sage' : 'Xero'} Data. Check back in a few minutes.`;
          dispatch(toggleAlertMessage(true, message, 'success', 600000));
        }
      }

      // Step Function Status is Succeeded
      if (status === 'SUCCEEDED') {
        const data = JSON.parse(response.data.data.output);
        console.log('Response Data', data);

        if (execArn.includes('RecalculateUsageHelper')) {
          // For Recalculate Usage
          if (data.message !== 'No usage data to be recalculated!' && data.type === 'recalculate') {
            // Just show the analysis modal when needed
            dispatch(processingStepFunctionStatus({ data: data }));
            dispatch(showAnalysisModal(true, 'recalculateUsage'));
            dispatch(toggleAlertMessage(false));
          }
        } else if (execArn?.includes('ImportBills')) {
          // For Xero Imports
          const sage = execArn.includes('Sage');
          const message = `Your data has been successfully loaded from ${sage ? 'Sage' : 'Xero'}.`;
          dispatch(toggleAlertMessage(true, message, 'success'));
          dispatch(processingStepFunctionStatus(false));
        } else if (execArn?.includes('ConsumptionHelper')) {
          // For Fetching Consumption Data
          dispatch(CONSUMPTION_ACTIONS.getConsumption(null, true));
        } else if (execArn?.includes('ReportHelper')) {
          // For Fetching Reports
          const fileType = JSON.parse(response.data.data.input)?.type;
          if (data.message) {
            // Show Alert if Organisation Has No Usage
            dispatch(toggleAlertMessage(true, data.message, 'error', 7000));
          } else {
            const popup = window.open(data.signedURL);
            if (!popup || popup.closed || typeof popup.closed === 'undefined') {
              const message = 'Popup Blocker is enabled! Please add this site to your exception list.';
              alert(message);
            }
          }
          dispatch(REPORT_ACTIONS.updateDownloadStatus(false, fileType));
        }
        return;
      }

      // Step Function Status Failed
      if (status === 'FAILED') {
        if (execArn.includes('RecalculateUsageHelper')) {
          // For Recalculate Usage
          const message = 'There was an error while recalculating Usage!';
          dispatch(toggleAlertMessage(true, message, 'error'));
        } else if (execArn?.includes('ImportBills')) {
          // For Xero Imports
          const message = 'Import malfunction, please contact support.';
          dispatch(toggleAlertMessage(true, message, 'error', 600000));
          dispatch(processingStepFunctionStatus(false));
        } else if (execArn?.includes('ConsumptionHelper')) {
          const message = 'Something went wrong while fetching your data, please contact support.';
          dispatch(toggleAlertMessage(true, message, 'error', 600000));
        } else if (execArn?.includes('ReportHelper')) {
          const fileType = JSON.parse(response.data.data.input)?.type;
          dispatch(REPORT_ACTIONS.updateDownloadStatus(false, fileType));
          alert('Popup Blocker is enabled! Please add this site to your exception list.');
        }
        return;
      }
    } catch (e) {
      console.log('getStepFunctionStatus Error:', e);
    }
  };
}

const delay = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const trackProgress = async (tableID, organisation) => {
  const response = await axios(
    await createSignedRequest('GET', apiURL + '/my/functionStatus/' + tableID, null, {
      Organisation: String(organisation),
    })
  );

  return response.data?.item;
};

const runningProcessor = async (fields) => {
  const { processedCounts, chunkedDataLength, totalDataLength, state, data, index, dispatch, type } = fields || {};
  const { proccessedCount, message, completionRate } = data || {};

  switch (type) {
    case 'usage': {
      if (chunkedDataLength > 1) dispatch(toggleAlertMessage(true, 'Usage Processing', 'success', 3000));
      processedCounts[index] = proccessedCount;
      const totalProcessed = processedCounts.reduce((a, b) => a + b, 0);
      const percentage = (totalProcessed / totalDataLength) * 100;
      dispatch(updateProgressBar(percentage));
      if (state.adminPortal || chunkedDataLength > 1) await delay(5000);
      break;
    }
    case 'importUsage': {
      dispatch(toggleAlertMessage(true, message, 'success', 3000));
      if (!completionRate) dispatch(showLoader(true));
      else dispatch(updateProgressBar(Number(completionRate)));

      await delay(5000);
      break;
    }

    case 'transferPortal': {
      dispatch(toggleAlertMessage(false, message, 'success', 3000));
      await delay(3000);
      break;
    }

    default:
      dispatch(toggleAlertMessage(true, message, 'success', 3000));
      await delay(3000);
      break;
  }
};

const successProcessor = async (fields) => {
  const { processedCounts, totalDataLength, state, data, index, dispatch, type } = fields || {};
  const { proccessedCount, message, output } = data || {};

  switch (type) {
    case 'usage': {
      if (!state.adminPortal) {
        // Fetch all org usage, after success
        dispatch(USAGE_ACTIONS.getUsageTotals());
      }
      processedCounts[index] = proccessedCount;
      const totalProcessed = processedCounts.reduce((a, b) => a + b, 0);
      const perecentage = (totalProcessed / totalDataLength) * 100;
      if (totalProcessed === totalDataLength) {
        dispatch(toggleAlertMessage(true, message, 'success', 3000));
        dispatch(updateProgressBar(perecentage));
      }
      break;
    }

    case 'importUsage': {
      dispatch(updateProgressBar(100));
      dispatch(toggleAlertMessage(true, 'Import Complete!', 'success', 2500));
      await delay(2000);
      if (output?.successes > 0) dispatch(USAGE_ACTIONS.getUsageTotals());
      dispatch(USAGE_ACTIONS.showAnalysisImportModal(true, output));
      dispatch(showLoader(false));
      break;
    }

    case 'transferPortal': {
      dispatch(toggleAlertMessage(false, message, 'success', 3000));
      break;
    }

    default:
      dispatch(toggleAlertMessage(true, message, 'success', 3000));
      break;
  }
};

export async function processTableID(tableID, fields) {
  // Fields is an object the properties needed for each execution plus dispatch, state and type
  const { dispatch, state } = fields || {};

  while (true) {
    // Fetches the status of each execution
    const data = await trackProgress(tableID, state.currentOrganisation);

    fields.data = data;

    const { status, message, output } = data || {};

    if (status === 'FAILED') {
      if (fields.type === 'importUsage' && output) {
        dispatch(USAGE_ACTIONS.showAnalysisImportModal(true, output));
      }
      dispatch(updateProgressBar(-1));
      dispatch(toggleAlertMessage(true, `Error: ${message}`, 'error', 3000));
      dispatch(showLoader(false));
      break;
    }

    if (status === 'RUNNING') {
      // Depending on the type of execution, do something while the execution is running
      await runningProcessor(fields);
      continue;
    }

    if (status === 'SUCCESS') {
      // Depending on the type of execution, do something while the execution is succeeded
      await successProcessor(fields);
      break;
    }
  }
}

const updateFeatures = (response) => ({
  type: Types.GET_FEATURES,
  payload: response,
});

export const getFeatures = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/admin/portal/features', null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getFeatures', response);
      dispatch(updateFeatures(response?.data?.items));
    } catch (e) {
      console.log('getFeatures Error:', e);
    }
  };
};

export const getAppState = () => {
  return async (dispatch, getState) => {
    const state = getState();
    return state;
  };
};

const mainAppExports = {
  getEntities,
  getSubentities,
  getLicenses,
};

const exports = {
  bulkAddXeroOptions,
  getSageAccountCodes,
  setSageAccountCode,
  getSageOrganisationSettings,
  saveSageOrganisationSettings,
  getSageSpendEntities,
  isConnectedToSage,
  sageCallBack,
  processSageImport,
  ...mainAppExports,
  getTrackingCategories,
  getAccountCodes,
  setAccountCode,
  isConnectedToXero,
  xeroCallback,
  selectTrackingCategory,
  setAllTrackingOptions,
  saveOrganisationSettings,
  saveTenant,
  setMethod,
  getOrganisationSettings,
  setSession,
  getYears,
  processImport,
  selectYear,
  Types,
  saveSageTenant,
  getSpendEntities,
  setTrackingOption,
  addTrackingOption,
  showFooterLegalAgreements,
  toggleAlertMessage,
  showModal,
  disconnectOrganisation,
  showAnalysisModal,
  showLoader,
  getStepFunctionStatus,
  processingStepFunctionStatus,
  processTableID,
  getFeatures,
};

export default exports;
