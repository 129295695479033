import React from 'react';
import ACTIONS from '../../store/action';
import { connect } from 'react-redux';
import _ from 'lodash';

import EmissionsDisaggregatedByOrganisationRow from './EmissionsDisaggregatedByOrganisationRow';

const EmissionsDisaggregatedByOrganisation = ({ organisations, includedOrgs }) => {
  if (!(organisations && Object?.keys(organisations)?.length > 0 && includedOrgs?.length > 0)) {
    return null;
  }

  return (
    <div>
      <br />
      <br />
      <h3>Emissions disaggregated by Organisation</h3>
      <table className='table table-hover general-table' style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <th width='20%'>Organisation</th>
            <th style={{ textAlign: 'right' }}>Total (tCO2e)</th>
            <th style={{ textAlign: 'right' }}>Scope 1 (tCO2e)</th>
            <th style={{ textAlign: 'right' }}>Scope 2 (tCO2e)</th>
            <th style={{ textAlign: 'right' }}>Scope 3 (tCO2e incl. WTT*)</th>
          </tr>
        </thead>
        <tbody>
          {organisations &&
            includedOrgs.length > 0 &&
            Object.keys(organisations)?.map((org) => (
              <EmissionsDisaggregatedByOrganisationRow key={org} orgID={org} organisations={organisations} />
            ))}
        </tbody>
      </table>
      <br></br>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { emissionsByFacility } = state.consumption;
  const facilities = _.uniqBy(state.facility?.hierarchyFacilities, 'id');
  const includedOrgs = state.report?.includedOrgs;

  const facilityKeys =
    emissionsByFacility &&
    state.report &&
    state.report.details &&
    state.report.details.year &&
    emissionsByFacility[state.report.details.year] &&
    Object.keys(emissionsByFacility[state.report.details.year]);

  const facilityListFilteredByKeys =
    facilities &&
    Array.isArray(facilities) &&
    facilities
      ?.filter((item) => facilityKeys && facilityKeys.includes(item.id.toString()))
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

  let organisations = {};
  let total = {
    co2e: 0,
    scope: {
      1: 0,
      2: 0,
      3: 0,
    },
    marketScope: {
      1: 0,
      2: 0,
      3: 0,
    },
    market_co2e: 0,
  };
  //extract all the information from emissionsByFacility[state.report.details.year] and store them in the correct organisationId object in the object
  for (let i = 0; i < facilityListFilteredByKeys.length; i++) {
    const facility = facilityListFilteredByKeys[i];
    const facilityData = emissionsByFacility[state.report.details.year][facility.id];
    const organisationId = facility.organisationId;
    if (organisations[organisationId]) {
      organisations[organisationId].co2e += facilityData.co2e;
      organisations[organisationId].scope = {
        1: organisations[organisationId].scope[1] + facilityData.scope[1],
        2: organisations[organisationId].scope[2] + facilityData.scope[2],
        3: organisations[organisationId].scope[3] + facilityData.scope[3],
      };
      organisations[organisationId].marketScope = {
        1: organisations[organisationId].marketScope[1] + facilityData.marketScope[1],
        2: organisations[organisationId].marketScope[2] + facilityData.marketScope[2],
        3: organisations[organisationId].marketScope[3] + facilityData.marketScope[3],
      };
      organisations[organisationId].market_co2e += facilityData.market_co2e;
    } else {
      organisations[organisationId] = {
        co2e: facilityData.co2e,
        scope: {
          1: facilityData.scope[1],
          2: facilityData.scope[2],
          3: facilityData.scope[3],
        },
        marketScope: {
          1: facilityData.marketScope[1],
          2: facilityData.marketScope[2],
          3: facilityData.marketScope[3],
        },
        market_co2e: facilityData.market_co2e,
      };
    }
    total.co2e += facilityData.co2e;
    total.scope[1] += facilityData.scope[1];
    total.scope[2] += facilityData.scope[2];
    total.scope[3] += facilityData.scope[3];
    total.marketScope[1] += facilityData.marketScope[1];
    total.marketScope[2] += facilityData.marketScope[2];
    total.marketScope[3] += facilityData.marketScope[3];
    total.market_co2e += facilityData.market_co2e;
  }

  organisations = Object?.fromEntries(Object?.entries(organisations)?.sort(([, orgA], [, orgB]) => orgB?.co2e - orgA?.co2e));
  organisations['all'] = total;

  return {
    organisations,
    includedOrgs,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsDisaggregatedByOrganisation);
